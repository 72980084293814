import Axios from 'axios';
import React, { Component } from 'react';
import { Card, CardBody, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, CardImg, Row, CardTitle, CardText, Button, Badge } from "reactstrap";

//Simple bar
import './../../assets/scss/custom/components/_enterprisesDash.scss'

class EnterprisesList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            enterprises:[],
        }
    }

    componentDidMount(){
        const data2 = {
            type: localStorage.getItem('access')
          }
          Axios.post(process.env.REACT_APP_API + '/api/auth/user-properties/' + localStorage.getItem('id') , data2,
            {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(response => {
                this.setState({imoveis: response.data?.enterprises?.enterprises})
            })
    }

    render() {
        return (
            <React.Fragment>
                <Col xs={12}>
                    <Card>
                        <CardBody style={{marginTop:'20px'}}>
                        <h4 className="card-title mb-4">Seus empreendimentos</h4>
                            <div className="enterpriseRankingGrid">
                            {this.state.imoveis?.length > 0 ? (this.state.imoveis?.map((enterprise, index) => (
                            <div key={index}  style={{cursor:'pointer'}} className="enterpriseRankingCard"  onClick={() => {
                                setTimeout(() => {
                                    window.open("/perfil-empreendimento?id=" + enterprise?.id , '_parent')
                                }, 100);
                            }}>
                                <div className="rankingCover" style={{
                                    backgroundImage:'url(' + enterprise?.photos?.sort((c1, c2) => (c1?.order > c2?.order) ? 1 : -1)[0]?.url + ')'
                                }}></div>
                                <div className="rankingTitle">
                                    {enterprise?.title}
                                </div>
                            </div>
                            ))):(
                                <p>Você ainda não tem empreendimentos cadastrados.</p>
                            )}
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </React.Fragment>
        );
    }
}

export default EnterprisesList;