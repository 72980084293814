import Bruno from './../assets/bruno.jpg';
import Pierre from './../assets/pierre.jpg';
import Vitorino from './../assets/vitorino.jpg';
import Gabriel from './../assets/gabriel.png';
import Antony from './../assets/antony.png';
import Marcele from './../assets/marcele.jpg';

const brokers = [
  {
    image: Bruno,
    name: 'Bruno Cameschi',
    text: 'Uma das plataformas mais completas para venda de imóveis...',
    profession: '@corretordadepressão',
    link: 'https://www.instagram.com/reel/CdY-nTuFf-V/?igshid=MWI4MTIyMDE%3D'
  },
  {
    image: Pierre,
    name: 'Pierre Cugnier',
    text: 'Fiz uma venda de 9 milhões utilizando a Fast Sale...',
    profession: 'Corretor Fast Sale',
    link: 'https://www.instagram.com/tv/CaZ41FBFKo0/?igshid=MWI4MTIyMDE%3D'
  },
  {
    image: Vitorino,
    name: 'Gabriel Vitorino',
    text: 'Em pouco tempo já fiz uma venda com a Fast',
    profession: 'Corretor Fast Premium',
    link: 'https://www.instagram.com/reel/C4OhRMMRzoG/?igsh=MWd3cG8xdWQweDl6Zw%3D%3D'
  },
  {
    image: Gabriel,
    name: 'Gabriel Neumann',
    text: 'Encontrei na plataforma o imóvel que meu cliente buscava',
    profession: 'Corretor Captador',
    link: 'https://www.instagram.com/p/C5g3l71xis6/'
  },
];

export default brokers;