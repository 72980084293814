import { useState } from 'react';
import { useEffect } from 'react';
// import '../UsersAnalytics/styles.css';
import './styles.css';
import { Link } from 'react-router-dom';
import UsersCharts from '../UsersCharts';
import './../../../assets/scss/custom/components/_dashboard_adm.scss'
import Axios from 'axios';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { PieChart, Pie, Legend, Tooltip, Cell } from 'recharts';

function UsersAnalyticsImmobile() {
  
  const COLORS = ['rgb(98, 138, 220)', 'rgb(98, 201, 14)'];

  const [immobilePerCityRef, setImmobilePerCityRef] = useState()
  const [immobilePerCity, setImmobilePerCity] = useState([])
  const [immobileFsRef, setImmobileFsRef] = useState()
  const [immobileFs, setImmobileFs] = useState([])
  const [immobileTotal, setImmobileTotal] = useState([])

  const [graphicData, setGraphicData] = useState('newsImmobile')
  const [graphicContent, setGraphicContent] = useState(immobileTotal?.imoveis_mes)
  const [noArray, setNoArray] = useState('noArray')

  useEffect(() => {
    if(immobilePerCityRef){
      Axios.post(process.env.REACT_APP_API + '/api/auth/dashboard/get-property-state', {'state': immobilePerCityRef},
      {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {
          setImmobilePerCity(response.data[0])
          console.log(response.data)
        })
    }
  }, [immobilePerCityRef])

  useEffect(() => {
    if(immobileFsRef){
      Axios.post(process.env.REACT_APP_API + '/api/auth/dashboard/get-property-city', {'city': immobileFsRef},
      {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {
          setImmobileFs(response.data.imoveis)
        })
    }
  }, [immobileFsRef])

  useEffect(() => {
    Axios.get(process.env.REACT_APP_API + '/api/auth/dashboard/get-count',
    {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
    .then(response => {
      setImmobileTotal(response.data)
    })
  },[])

  useEffect(() => {
    if(graphicData === 'newsImmobile'){
      setGraphicContent(immobileTotal?.imoveis_mes)
      setNoArray('noArray')
    }
    if(graphicData === 'apiCanal'){
      setGraphicContent(immobileTotal?.canal_pro_mes)
      setNoArray('')
    }
    if(graphicData === 'apiChaves'){
      setGraphicContent(immobileTotal?.chaves_mao_mes)
      setNoArray('')
    }
    if(graphicData === 'views'){
      setGraphicContent(immobileTotal?.views_mes)
      setNoArray('noArray')
    }
    if(graphicData === 'downloads'){
      setGraphicContent(immobileTotal?.downloads_mes)
      setNoArray('noArray')
    }
    if(graphicData === 'sendImmobile'){
      setGraphicContent(immobileTotal?.shares_mes)
      setNoArray('noArray')
    }
    if(graphicData === 'capChat'){
      setGraphicContent(immobileTotal?.talks_mes)
      setNoArray('noArray')
    }
  }, [graphicData])

  const totalData = [
    { name: 'Parceria: R$ ' + (parseInt(immobileTotal?.imoveis_contrato_total) / 100).toLocaleString('pt-br', {minimumFractionDigits: 2}), value: parseInt(immobileTotal?.imoveis_contrato_total) / 100},
    { name: 'Fast Sale: R$ ' + (parseInt(immobileTotal?.imoveis_contrato_fs_total) / 100).toLocaleString('pt-br', {minimumFractionDigits: 2}), value: parseInt(immobileTotal?.imoveis_contrato_fs_total) / 100},
  ];

  const sellData = [
    { name: 'Externo: R$ ' + (parseInt(immobileTotal?.imoveis_vendidos_ext_value) / 100).toLocaleString('pt-br', {minimumFractionDigits: 2}), value: parseInt(immobileTotal?.imoveis_vendidos_ext_value) / 100},
    { name: 'Fast Sale: R$ ' + (parseInt(immobileTotal?.imoveis_vendidos_value) / 100).toLocaleString('pt-br', {minimumFractionDigits: 2}), value: parseInt(immobileTotal?.imoveis_vendidos_value) / 100},
  ];

  return (
  <Col xs={12}>
    <Card>
      <CardBody>
        <div className="corretorTitle">
          <h3 style={{fontSize:'20px'}}>Números de imóveis</h3>
        </div>
        <div className="userType imoveis UsersAnalytics">
          <Row>
            <Col>
              <Row>
                <Col>
                  <div className="title">
                    <h4>Quantidade por status:</h4>
                  </div>
                </Col>
              </Row>
              <Row  style={{gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))', display:'grid', gap: '10px'}}>
                <Col><div className="card" style={{backgroundColor:'#f1ce41'}}>Pendentes<span>{immobileTotal?.imoveis_pendentes}</span></div></Col>
                <Col><div className="card" style={{backgroundColor:'#a41c2a'}}>Cancelados<span>{immobileTotal?.imoveis_cancelados}</span></div></Col>
                <Col><div className="card" style={{backgroundColor:'#da2c3e'}}>Reprovados<span>{immobileTotal?.imoveis_bloqueados}</span></div></Col>
                <Col><div className="card" style={{backgroundColor:'#888'}}>Bloqueados Pgto.<span>{immobileTotal?.imoveis_bloqueados}</span></div></Col>
                <Col><div className="card" style={{backgroundColor:'#cdcdcd'}}>Inativos<span>{immobileTotal?.imoveis_inativos}</span></div></Col>
                <Col><div className="card" style={{backgroundColor:'#49920e'}}>Vendidos FS<span>{immobileTotal?.imoveis_vendidos_fs_total}</span></div></Col>
                <Col><div className="card" style={{backgroundColor:'#62c90e'}}>Contrato FS<span>{immobileTotal?.imoveis_contrato_fs}</span></div></Col>
                <Col><div className="card" style={{backgroundColor:'#2a4988'}}>Vendidos EXT.<span>{immobileTotal?.imoveis_vendidos_ext_total}</span></div></Col>
                <Col><div className="card" style={{backgroundColor:'#628adc'}}>Parceria<span>{immobileTotal?.imoveis_contrato}</span></div></Col>
                <Col><div className="card" style={{backgroundColor:'#0f6e43'}}>Total Ativos<span>{immobileTotal?.imoveis_ativos}</span></div></Col>
              </Row>
            </Col>
          </Row>
          <Row>  
            <Col lg={6}>
              <Row>
                <Col>
                  <div className="title">
                    <h4>Ranking de downloads por imóvel:</h4>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="ranking-container">
                    <div className="item title">
                      <div className="position">Posição:</div>
                      <div className="cover">Imóvel:</div>
                      <div className="info"></div>
                      <div className="value"></div>
                      <div className="immobile">Downloads:</div>
                    </div>
                    {immobileTotal?.imoves_downloads?.sort(function(a, b){return b?.downloads - a?.downloads}).map((imovel, index) => (
                      <div className="item">
                        <div className="position">{index + 1}º</div>
                        <div className="cover">
                          <div className="image" style={{backgroundImage:'url(' + imovel?.one_photo?.small_image + ')'}}></div>
                        </div>
                        <div className="info">
                          <div className="name"><h3>{imovel?.title}</h3></div>
                          <div className="city"><small>{imovel?.city}</small></div>
                        </div>
                        <div className="value">
                          <div className="value"><p>R$ {(imovel?.sale_value / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}</p></div>
                        </div>
                        <div className="immobile">{imovel?.downloads}</div>
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <Row>
                  <Col>
                    <div className="title">
                      <h4>Ranking de visualizações por imóvel:</h4>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="ranking-container">
                      <div className="item title">
                        <div className="position">Posição:</div>
                        <div className="cover">Imóvel:</div>
                        <div className="info"></div>
                        <div className="value"></div>
                        <div className="immobile">Visualizações:</div>
                      </div>
                      {immobileTotal?.imoves_visualizacoes?.sort(function(a, b){return b?.views - a?.views}).map((imovel, index) => (
                        <div className="item">
                          <div className="position">{index + 1}º</div>
                          <div className="cover">
                            <div className="image" style={{backgroundImage:'url(' + imovel?.one_photo?.small_image + ')'}}></div>
                          </div>
                          <div className="info">
                            <div className="name"><h3>{imovel?.title}</h3></div>
                            <div className="city"><small>{imovel?.properties?.city}</small></div>
                          </div>
                          <div className="value">
                            <div className="value"><p>R$ {}{(imovel?.sale_value / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}</p></div>
                          </div>
                          <div className="immobile">{imovel?.views}</div>
                        </div>
                      ))}
                    </div>
                  </Col>
                </Row>    
              </Col>
            </Row>
            <Row>
              <Col>
                <Row>
                  <Col>
                    <div className="title" style={{margin:'30px 0 10px'}}>
                      <h4>Ações na plataforma:</h4>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="cardGraphic" style={graphicData === 'newsImmobile' ? {backgroundColor: "#62c90e" } : {}} onClick={() => {
                      setGraphicData('newsImmobile')
                    }}>Novos Imóveis</div>
                  </Col>
                  <Col>
                    <div className="cardGraphic" style={graphicData === 'apiCanal' ? {backgroundColor: "#62c90e" } : {}} onClick={() => {
                      setGraphicData('apiCanal')
                    }}>Canal Pro</div>
                  </Col>
                  <Col>
                    <div className="cardGraphic" style={graphicData === 'apiChaves' ? {backgroundColor: "#62c90e" } : {}} onClick={() => {
                      setGraphicData('apiChaves')
                    }}>Chaves na Mão</div>
                  </Col>
                  <Col>
                    <div className="cardGraphic" style={graphicData === 'views' ? {backgroundColor: "#62c90e" } : {}} onClick={() => {
                      setGraphicData('views')
                    }}>Visualizações</div>
                  </Col>
                  <Col>
                    <div className="cardGraphic" style={graphicData === 'downloads' ? {backgroundColor: "#62c90e" } : {}} onClick={() => {
                      setGraphicData('downloads')
                    }}>Downloads</div>
                  </Col>
                  <Col>
                    <div className="cardGraphic" style={graphicData === 'sendImmobile' ? {backgroundColor: "#62c90e" } : {}} onClick={() => {
                      setGraphicData('sendImmobile')
                    }}>Compartilhamentos</div>
                  </Col>
                  <Col>
                    <div className="cardGraphic" style={graphicData === 'capChat' ? {backgroundColor: "#62c90e" } : {}} onClick={() => {
                      setGraphicData('capChat')
                    }}>Conversas Corretor</div>
                  </Col>
                </Row>
                <div className="immobileCharts">
                  <UsersCharts items={graphicContent} model={noArray}/>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="title">
                  <h4>Imóveis em valores:</h4>
                </div>
                <div className="row pieChart"> 
                  <div className="col">
                    <h4 style={{fontWeight:'300'}}>Imóveis ativos</h4>
                    <PieChart width={400} height={400}>
                      <Pie
                        data={totalData}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        innerRadius={60}
                        outerRadius={80}
                        label={({ value, name }) => `${((value / totalData.reduce((acc, curr) => acc + curr.value, 0)) * 100).toFixed(2)}%`}
                        >
                          {totalData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                      </Pie>
                      <Legend />
                      <Tooltip
                        formatter={(value, name, props) => {
                          const total = totalData.reduce((prev, cur) => prev + cur.value, 0);
                          const percent = ((value / total) * 100).toFixed(2);
                          return [`(${percent}%)`, name];
                        }}
                      />
                    </PieChart>
                  </div>
                  <div className="col">
                    <h4 style={{fontWeight:'300'}}>Imóveis vendidos</h4>
                    <PieChart width={400} height={400}>
                      <Pie
                        data={sellData}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        innerRadius={60}
                        outerRadius={80}
                        label={({ value, name }) => `${((value / sellData.reduce((acc, curr) => acc + curr.value, 0)) * 100).toFixed(2)}%`}
                        >
                          {sellData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                      </Pie>
                      <Legend />
                      <Tooltip
                        formatter={(value, name, props) => {
                          const total = sellData.reduce((prev, cur) => prev + cur.value, 0);
                          const percent = ((value / total) * 100).toFixed(2);
                          return [`(${percent}%)`, name];
                        }}
                      />
                    </PieChart>
                  </div>
                  <div className="col totalImmobileValue">
                    <div className="item total">Total em imóveis ativos: <span>R$ {parseInt(immobileTotal?.imoveis_valor / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}</span></div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <div className="title">
                <h4>Quantidade por localidade:</h4>
              </div>
              <div className="row">
                <div className="col">
                  <div className="header">
                    <div className="col">Estado</div>
                    <div className="col">Nº de Imóveis</div>
                  </div>
                  <div className="table">
                    {immobileTotal?.imoveis_estado?.sort(function(a, b){return b?.count - a?.count}).map((state, index) => (
                      <div key={index} className="body" onClick={() => setImmobilePerCityRef(state?.name)}>
                        <div className="col">{state?.name}</div>
                        <div className="col">{state?.count}</div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col">
                  <div className="header">
                    <div className="col">Cidade</div>
                    <div className="col">Nº de Imóveis</div>
                  </div>
                  <div className="table">
                    {immobilePerCity?.sort(function(a, b){return b?.count - a?.count}).map((city, index) => (
                      <div className="body" onClick={() => setImmobileFsRef(city?.name)}>
                        <div className="col">{city?.name}</div>
                        <div className="col">{city?.count}</div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col">
                  <div className="header">
                    <div className="col">Imóvel</div>
                    <div className="col">Contrato</div>
                  </div>
                  <div className="table">
                    {/* {immobileFs?.sort(function(a, b){return b?.premium - a?.premium}).map((immobile, index) => ( */}
                    {immobileFs?.map((immobile, index) => (
                      <Link key={index} to={'../editar-imoveis/editar-infos?'  + immobile.id} className="body" target="_blank">
                        <div className="col">{immobile?.title}</div>
                        <div className="col"><span className="premiumTrue">{immobile?.sell_contract_type}</span></div>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </Row>
        </div>
      </CardBody>
    </Card>
  </Col>
  );
}
  
export default UsersAnalyticsImmobile;  