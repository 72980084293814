import React, { Component } from 'react';
import { Col, Dropdown, DropdownMenu, DropdownToggle, DropdownItem, Card, CardBody, Row, Container, Input, Label, Badge, Spinner, Button, CardHeader, Collapse, Modal, ModalHeader, ModalBody, ModalFooter, CardTitle, CardText, Progress } from "reactstrap";
import { Link, useParams } from "react-router-dom";
import Axios from 'axios'

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Breadcrumbs from "../../components/Common/Breadcrumb"

import './../../assets/scss/custom/components/_singleProperty.scss'

import moment from 'moment';
import ReactSelect from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import Fire from './../../assets/images/Icons/fogooutlet.png';
import SingleImovelCardCarousel from './Carousel';
import Maps from './Maps';
import UnitsAction from './UnitsAction';
import { saveAs } from 'file-saver';


import 'swiper/css';
import "swiper/css/navigation";
// import "swiper/scrollbar.css"


import './../../assets/scss/custom/components/_singleUnits.scss'
import Lightbox from 'react-image-lightbox';
import { NewIcons } from './newIcons';
import { LoginModal } from '../Ecommerce/newCheckout/components/loginModal';

require('moment/locale/pt.js');

class SingleEnterprise extends Component {
  constructor(props) {
    super(props);
    this.state = {
      col1: true,
			col2: false,
			col3: false,
			col5: true,
      imovel:[],
      modal_large: false,
      modal_gallery: false,
      url:"",
      minRooms:'',
      maxRooms:'',
      minSuites:'',
      maxSuites:'',
      minGarage:'',
      maxGarage:'',
      minBath:'',
      maxBath:'',
      minPrivate:'',
      maxPrivate:'',
      minTotal:'',
      isGalleryZoom:false,
      maxTotal:'',
      allPhotos:[],
      photoIndex:null,
      gallerySelected:'photos',
      activeDownload:false,
      galleryItemsSelected:[],
      loginOpen:true,
      breadcrumbItems: [
        { title: "Fast Sale", link: "/dashboard" },
        { title: "Imóveis", link: "/imoveis" },
        { title: '', link: "#" },
    ],
    }
    this.t_col1 = this.t_col1.bind(this);
		this.t_col2 = this.t_col2.bind(this);
		this.t_col3 = this.t_col3.bind(this);
		this.t_col4 = this.t_col4.bind(this);
		this.t_col5 = this.t_col5.bind(this);
		this.handleGallery = this.handleGallery.bind(this);
		this.handleLp = this.handleLp.bind(this);
		this.handleCatalog = this.handleCatalog.bind(this);
		this.handleContact = this.handleContact.bind(this);
  }


  t_col1() {
		this.setState({ col1: !this.state.col1, col2: false, col3: false, col4: false });
	}
	t_col2() {
		this.setState({ col2: !this.state.col2, col1: false, col3: false, col4: false });
	}
	t_col3() {
		this.setState({ col3: !this.state.col3, col1: false, col2: false, col4: false });
	}
  t_col4() {
		this.setState({ col4: !this.state.col4, col1: false, col2: false, col3: false });
	}
	t_col5() {
		this.setState({ col5: !this.state.col5 });
	}


  componentDidMount(){
    let slug = this.props.match.params

    const searchOwnerProperty = window.location.search;

      if(searchOwnerProperty === '?owner'){
        this.setState({url : "/api/auth/property-slug-pending/"})
      }else{
        this.setState({url: "/api/auth/enterprise/property-enterprises-slug/"})
      }

      Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
        type: localStorage.getItem('access'),
        user_id: localStorage.getItem('id'),
        }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {

          Axios.get(process.env.REACT_APP_API + "/api/auth/enterprise/property-enterprises-slug/" + slug.slug,
          {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
          .then(response => {
            this.setState({imovel: response.data})
            localStorage.setItem('view_property_sku', response.data.sku)

            const enterprise = response.data;

            let minRooms = Infinity;
            let maxRooms = -Infinity;
            let minSuites = Infinity;
            let maxSuites = -Infinity;
            let minGarage = Infinity;
            let maxGarage = -Infinity;
            let minBath = Infinity;
            let maxBath = -Infinity;
            let minPrivate = Infinity;
            let maxPrivate = -Infinity;
            let minTotal = Infinity;
            let maxTotal = -Infinity;
            
            enterprise.types.forEach(type => {
              minRooms = Math.min(minRooms, type.rooms);
              maxRooms = Math.max(maxRooms, type.rooms);
              minSuites = Math.min(minSuites, type.suites);
              maxSuites = Math.max(maxSuites, type.suites);
              minGarage = Math.min(minGarage, type.garage);
              maxGarage = Math.max(maxGarage, type.garage);
              minBath = Math.min(minBath, type.bathrooms);
              maxBath = Math.max(maxBath, type.bathrooms);
              minPrivate = Math.min(minPrivate, type.private_area);
              maxPrivate = Math.max(maxPrivate, type.private_area);
              minTotal = Math.min(minTotal, type.total_area);
              maxTotal = Math.max(maxTotal, type.total_area);
            });

            this.setState({
              minRooms: minRooms,
              maxRooms: maxRooms,
              minSuites: minSuites,
              maxSuites: maxSuites,
              minGarage: minGarage,
              maxGarage: maxGarage,
              minBath: minBath,
              maxBath: maxBath,
              minPrivate: minPrivate,
              maxPrivate: maxPrivate,
              minTotal: minTotal,
              maxTotal: maxTotal,
            });

            //Verifica usuário sem documentos
            if(localStorage.getItem('status') === '3' || localStorage.getItem('status') === '2'){
              this.setState({verifyDocs: false})
            }


            this.setState({breadcrumbItems: [
              { title: "Fast Sale", link: "/dashboard" }, 
              { title: "Imóveis", link: "/imoveis" },
              { title: response.data.sku, link: "#" },
            ]})

            const data = new FormData()
            if(localStorage.getItem('access') === 'C'){data.append('broker_id', localStorage.getItem('id'))}
            if(localStorage.getItem('access') === 'I'){data.append('real_estate_id', localStorage.getItem('id'))}
            data.append('enterprise_id', response.data.id)
            data.append('type_action', 'view')
    
            Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/enterprises-actions', data,
            {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})


           })
           .catch(response =>{
             localStorage.removeItem("history_url")
             setTimeout(() => {
               window.open("/404", '_parent')
             }, 300);})
         })
        .catch(response =>{
          setTimeout(() => {
            window.open("/login", '_parent')
          }, 300);
        })
  }

  togglePhotoSelection = (item) => {
    this.setState((prevState) => {
      const { galleryItemsSelected } = prevState;
      const photoIndex = galleryItemsSelected.findIndex((selectedItem) => (
        selectedItem.type === item.type && selectedItem.id === item.id
      ));
  
      if (photoIndex === -1) {
        return { galleryItemsSelected: [...galleryItemsSelected, item] };
      } else {
        galleryItemsSelected.splice(photoIndex, 1);
        return { galleryItemsSelected: galleryItemsSelected };
      }
    });
  };

  
  downloadSelectedItems = () => {

    document.getElementById('load').classList.add('active')

    const {imovel} = this.state;
  
    // Fazer uma solicitação POST para o servidor
    const axiosOptions = {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    }

    Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/gallery-download', {selectedItems : this.state.galleryItemsSelected},
    axiosOptions)
      .then((response) => {
        if (response.status === 200) {
          const filename = imovel?.title + '.zip';
          const blob = new Blob([response.data], { type: 'application/octet-stream' });
          saveAs(blob, filename);
          document.getElementById('load').classList.remove('active');
        } else {
          // Lide com erros, se necessário
        }
      })
      .catch((error) => {
        document.getElementById('load').classList.remove('active')
      });
  };

  handleGallery = () => {
    const {imovel} = this.state;

    const data = new FormData()
    if(localStorage.getItem('access') === 'C'){data.append('broker_id', localStorage.getItem('id'))}
    if(localStorage.getItem('access') === 'I'){data.append('real_estate_id', localStorage.getItem('id'))}
    data.append('enterprise_id', imovel?.id)
    data.append('type_action', 'gallery')

    Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/enterprises-actions', data,
    {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
    .then(response => {
        this.setState({modal_gallery: true})
    })
  }

  handleLp = () => {
    const {imovel} = this.state;

    const data = new FormData()
    if(localStorage.getItem('access') === 'C'){data.append('broker_id', localStorage.getItem('id'))}
    if(localStorage.getItem('access') === 'I'){data.append('real_estate_id', localStorage.getItem('id'))}
    data.append('enterprise_id', imovel?.id)
    data.append('type_action', 'site')

    Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/enterprises-actions', data,
    {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
    .then(response => {
      setTimeout(() => {
        window.open('https://share.fastsaleimoveis.com.br/empreendimento/' + imovel?.slug + '?id=' + localStorage.getItem('id') + '&type=' + localStorage.getItem('access'), '_blank')
      }, 0);
    })    
  }

  handleCatalog = () => {
    const {imovel} = this.state;

    const data = new FormData()
    if(localStorage.getItem('access') === 'C'){data.append('broker_id', localStorage.getItem('id'))}
    if(localStorage.getItem('access') === 'I'){data.append('real_estate_id', localStorage.getItem('id'))}
    data.append('enterprise_id', imovel?.id)
    data.append('type_action', 'download')

    Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/enterprises-actions', data,
    {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
    .then(response => {

      setTimeout(() => {
        window.open(imovel?.catalog, '_blank')
      }, 100);
      
    })  
  }

  handleContact = () => {
    const {imovel} = this.state;

    const data = new FormData()
    if(localStorage.getItem('access') === 'C'){data.append('broker_id', localStorage.getItem('id'))}
    if(localStorage.getItem('access') === 'I'){data.append('real_estate_id', localStorage.getItem('id'))}
    data.append('enterprise_id', imovel?.id)
    data.append('type_action', 'contact')

    Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/enterprises-actions', data,
    {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
    .then(response => {
      setTimeout(() => {
        window.open(`https://api.whatsapp.com/send?phone=55` + (imovel?.construction?.phone) + `&text=Olá,%20vi%20esse%20empreendimento%20na%20Fast%20Sale: ` + imovel?.title, '_blank')
      }, 0);
    })
  }


handleSuccess = () => {

}
  
  render() {

    const {imovel, allPhotos, photoIndex, activeDownload } = this.state;

    return (
      <div className="page-content singleProperty singleEnterprise">

        {/* <LoginModal open={this.state.loginOpen} onClose={() => this.setState({loginOpen:false})} success={this.handleSuccess} callbackUrl={window.location.pathname}/> */}

        <Container fluid={true} id="singleEnterprise">
          <Breadcrumbs title={'Empreendimento'} breadcrumbItems={this.state.breadcrumbItems} />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row style={{marginBottom:'24px'}}>
                    <Col style={{padding:'0', position:'relative'}}>
                      <SingleImovelCardCarousel enterprise={true} photos={imovel?.photos} imovel={imovel}/>
                      <div className="construction-logo" style={{backgroundImage:'url(' + imovel?.construction?.profile_photo + ')'}}></div>
                      <div className="construction-contact" onClick={this.handleContact}>
                        <i className="ri-whatsapp-line align-middle ms-2"></i>
                      </div>

                      <div className="mobile-actions-container">
                        <div className="enterprise-action" onClick={this.handleGallery}>
                          <NewIcons icon="gallery"/>
                          <p>Galeria</p>
                        </div>
                        <div className="enterprise-action" onClick={this.handleLp}>
                          <NewIcons icon="lp"/>
                          <p>Landing<br></br>Page</p>
                        </div>
                        <div className="enterprise-action" onClick={this.handleCatalog}>
                          <NewIcons icon="catalog"/>
                          <p>Catálogo</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row style={{justifyContent:'space-between'}}>
                    <Col md={6} lg={7}>
                      <Row>
                        <Col xs={12} md={9}>
                          <p className='sku' style={{display:'flex', alignItems:'center'}}>{imovel.sku}</p>
                          <div style={{display:'flex', flexDirection:'row', alignItems:'flex-start'}}>
                            <h1>{imovel.title}</h1>
                          </div>
                          <div className='place'>
                            <i className="ri-map-pin-2-fill"></i>
                            <p>{imovel?.street}, nº {imovel?.number}, {imovel?.district}, {imovel?.city} / {imovel?.state}</p>
                          </div>
                        </Col>
                        <Col xs={12} md={3}>
                          <img className="enterpriseLogo" src={imovel?.logo}></img>
                        </Col>
                        <Col xs={12}>
                          <Row className="enterprise-skills-desktop" style={{maxWidth:'600px'}}>
                            <Col className="highFeature col-auto">
                              <i className="ri-hotel-bed-fill"></i>
                              <p>{this.state.minSuites} a {this.state.maxSuites} suítes</p>
                            </Col>
                            <Col className="highFeature col-auto">
                              <i className="ri-hotel-bed-fill"></i>
                              <p>{this.state.minRooms} a {this.state.maxRooms} quartos</p>
                            </Col>
                            <Col className="highFeature col-auto">
                              <i className="ri-car-fill"></i>
                              <p>{this.state.minGarage} a {this.state.maxGarage} vagas</p>
                            </Col>
                            {!isNaN(this.state.minPrivate) && !isNaN(this.state.maxPrivate) && 
                            <Col className="highFeature col-auto">
                              <i className="ri-ruler-fill"></i>
                                <p>{this.state.minPrivate} a {this.state.maxPrivate} m² área privativa</p>
                            </Col>
                            }
                            {!isNaN(this.state.minTotal) && !isNaN(this.state.maxTotal) && 
                            <Col className="highFeature col-auto">
                              <i className="ri-ruler-fill"></i>
                              <p>{this.state.minTotal} a {this.state.maxTotal} m² área total</p>
                            </Col>
                            }
                            {/* <Col className="highFeature">
                              <Button onClick={this.tog_large} className="btn-map">
                                <p style={{color:'#333', fontWeight:'600', fontSize:'16px'}}>Mapa</p>
                              </Button>
                            </Col> */}
                          </Row>
                          <Row>
                            <div className="buttons-container">
                              <Button type="button" className="waves-effect waves-light me-1" onClick={this.handleGallery}>Galeria<i className="ri-image-line align-middle ms-2"></i></Button>
                              <Button type="button" className="waves-effect waves-light me-1" onClick={this.handleLp}>Landing Page<i className="ri-global-line align-middle ms-2"></i></Button>
                              {imovel?.catalog &&
                                <Button type="button" className="waves-effect waves-light me-1" onClick={this.handleCatalog}>Catálogo<i className="ri-file-list-2-line align-middle ms-2"></i></Button>
                            }
                              <Button type="button" className="waves-effect waves-light me-1" onClick={this.handleContact}>Falar com a construtora<i className="ri-whatsapp-line align-middle ms-2"></i></Button>
                              <Button type="button" className="waves-effect waves-light me-1" onClick={this.handleContact}>Agendar treinamento<i className="ri-book-2-line align-middle ms-2"></i></Button>
                            </div>
                          </Row>
                          <Row className="andamento-obra">
                            <Col md={6}>
                                <p style={{fontSize:'16px', fontWeight:'600'}}>Andamento da obra: {imovel?.progress}%</p>
                                <div style={{marginTop:'20px'}}><Progress striped animated color="primary" value={imovel?.progress} /></div>
                            </Col>
                            <Col className="progressContainer" md={6} style={{paddingLeft:'50px'}}>
                              <div>
                                <p>Início da obra:</p>
                                <p style={{fontSize:'16px', fontWeight:'600'}}>{moment(imovel?.start, "DD-MM-YYYY").format("MMMM [de] YYYY")}</p>
                              </div>
                              <div style={{marginTop:'20px'}}>
                                <p>Previsão de entrega:</p>
                                <p style={{fontSize:'16px', fontWeight:'600'}}>{moment(imovel?.delivery, "DD-MM-YYYY").format("MMMM [de] YYYY")}</p>
                              </div>
                            </Col>
                          </Row>
                          <div className="enterprise-skills-mobile">
                            <div className="highFeature">
                              <NewIcons icon="rooms"/>
                              <p>{this.state.minRooms} a {this.state.maxRooms} quartos</p>
                            </div>
                            <div className="highFeature">
                              <NewIcons icon="parking"/>
                              <p>{this.state.minGarage} a {this.state.maxGarage} vagas</p>
                            </div>
                            <div className="highFeature">
                              <NewIcons icon="area"/>
                              <p>{this.state.minPrivate} a {this.state.maxPrivate} m²</p>
                            </div>
                          </div>
                          <Row>
                            <div id="accordion">
                              <Card className="mb-1 shadow-none" style={{marginTop:'30px', borderBottom:'solid 1px #cdcdcd'}}>
                                <Link to="#" onClick={this.t_col1} style={{ cursor: "pointer" }} className="text-dark" >
                                  <CardHeader id="headingOne">
                                    <h6 className="m-0 font-14">
                                      <h3>Sobre o empreendimento</h3>
                                      <div className="text_muted acc-subtitle"><p>Veja mais informações sobre esse empreendimento</p></div>
                                      <i className={this.state.col1 ? "mdi mdi-minus float-end accor-plus-icon" : "mdi mdi-plus float-end accor-plus-icon"}></i>
                                    </h6>
                                  </CardHeader>
                                </Link>
                                <Collapse isOpen={this.state.col1}>
                                  <CardBody>
                                    <pre-line>{imovel.description}</pre-line>
                                  </CardBody>
                                </Collapse>
                              </Card>
                              <Card className="mb-1 shadow-none" style={{marginTop:'30px', borderBottom:'solid 1px #cdcdcd'}}>
                                <Link to="#" onClick={this.t_col2} style={{ cursor: "pointer" }} className="text-dark" >
                                  <CardHeader id="headingTwo">
                                    <h6 className="m-0 font-14">
                                      <h3>Características</h3>
                                      <div className="text_muted acc-subtitle"><p>Características do empreendimento</p></div>
                                      <i className={this.state.col2 ? "mdi mdi-minus float-end accor-plus-icon" : "mdi mdi-plus float-end accor-plus-icon"}></i>
                                    </h6>
                                  </CardHeader>
                                </Link>
                                <Collapse isOpen={this.state.col2}>
                                  <CardBody className="tags">
                                    {imovel?.details?.map((feat, index) => (
                                      <li key={index}>{feat.detail}</li>
                                    ))}
                                  </CardBody>
                                </Collapse>{" "}
                              </Card>
                              {(imovel?.accept_car === 1 || imovel?.accept_property === 1 || imovel?.real_state_financing === 1) &&
                              <Card className="mb-1 shadow-none" style={{marginTop:'30px', borderBottom:'solid 1px #cdcdcd'}}>
                                <Link to="#" onClick={this.t_col4} style={{ cursor: "pointer" }} className="text-dark" >
                                  <CardHeader id="headingTwo">
                                    {/* <h6 className="m-0 font-14">
                                      {" "}Outras características{" "}
                                      <i className={this.state.col2 ? "mdi mdi-minus float-end accor-plus-icon" : "mdi mdi-plus float-end accor-plus-icon"}></i>
                                    </h6> */}
                                    <h6 className="m-0 font-14">
                                      <h3>Condições de negociação</h3>
                                      <div className="text_muted acc-subtitle"><p>Condições para negociação do imóvel</p></div>
                                      <i className={this.state.col4 ? "mdi mdi-minus float-end accor-plus-icon" : "mdi mdi-plus float-end accor-plus-icon"}></i>
                                    </h6>
                                  </CardHeader>
                                </Link>
                                <Collapse isOpen={this.state.col4}>
                                  <CardBody className="negotiation">
                                  <ul style={{listStyle:'none', padding:'0'}}>
                                    {imovel?.accept_car === 1 &&
                                      <li style={{fontSize:'18px', display:'flex', alignItems:'center'}}><i class="ri-checkbox-circle-fill" style={{color:'green'}}></i>Aceita carro</li>
                                    }
                                    {imovel?.accept_property === 1 &&
                                      <li style={{fontSize:'18px', display:'flex', alignItems:'center'}}><i class="ri-checkbox-circle-fill" style={{color:'green'}}></i>Aceita imóveis</li>
                                    }
                                    {imovel?.real_state_financing === 1 &&
                                      <li style={{fontSize:'18px', display:'flex', alignItems:'center'}}><i class="ri-checkbox-circle-fill" style={{color:'green'}}></i>Aceita Fin. Imob.</li>
                                    }
                                  </ul>
                                  </CardBody>
                                </Collapse>{" "}
                              </Card>
                              }
                              {(imovel?.videos?.length > 0) &&
                              <Card className="mb-1 shadow-none" style={{marginTop:'30px', borderBottom:'solid 1px #cdcdcd'}}>
                                <Link to="#" onClick={this.t_col3} style={{ cursor: "pointer" }} className="text-dark" >
                                  <CardHeader id="headingThree">
                                    <h6 className="m-0 font-14">
                                      <h3>Vídeo</h3>
                                      <div className="text_muted acc-subtitle"><p>Assista vídeos do empreendimento</p></div>
                                      <i className={this.state.col2 ? "mdi mdi-minus float-end accor-plus-icon" : "mdi mdi-plus float-end accor-plus-icon"}></i>
                                    </h6>
                                  </CardHeader>
                                </Link>
                                <Collapse isOpen={this.state.col3}>
                                  <CardBody style={{gap:'20px', display:'flex', flexWrap:'wrap'}}>
                                    {imovel?.videos?.map((video, index) => (
                                      <video key={index} width="400" controls>
                                        <source src={video.url} type="video/mp4"></source>
                                        Seu navegador não suporte vídeos.
                                      </video>
                                    ))}
                                  </CardBody>
                                </Collapse>{" "}
                              </Card>
                              }
                            </div>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} lg={5} style={{justifyContent: 'flex-start', display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                        <UnitsAction
                          types={imovel?.types}
                        />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* Galeria */}
          <div
            // size="xl"
            // centered={true}
            // isOpen={this.state.modal_gallery}
            // toggle={() => this.setState({ modal_gallery: false })}
            className={`catalog-select-modal ${this.state.modal_gallery ? 'active' : ''}`}
          >
            {/* <div toggle={() => this.setState({ modal_gallery: false })}> */}
            <div className="catalog-select-modal-title">
              <span className="catalog-modal-close" onClick={() => this.setState({modal_gallery: false})}>
                <i className="ri-close-line"></i>
              </span>
               <div className="gallery-enterprise-title"><h3>Galeria</h3>
                <div className={`select-to-download ${activeDownload ? 'active' : ''}`}>
                  <input
                      id="activeDownload"
                      name="activeDownload"
                      type="checkbox"
                      checked={this.state.activeDownload}
                      onChange={() => this.setState({ activeDownload: !this.state.activeDownload })}
                  />
                  <label htmlFor='activeDownload'>Selecionar para download</label>
                </div>
                </div>
            </div>
            <div className="catalog-select-modal-body">
              <Container>
                <Row className="grid-buttons-galery">
                  {imovel?.photos?.length > 0 && <Col className="col-auto"><Button className={`button-gallery ${this.state.gallerySelected === 'photos' ? 'active' : ''}`} onClick={() => this.setState({gallerySelected: 'photos'})}>Fotos</Button></Col>}
                  {imovel?.videos?.length > 0 && <Col className="col-auto"><Button className={`button-gallery ${this.state.gallerySelected === 'videos' ? 'active' : ''}`} onClick={() => this.setState({gallerySelected: 'videos'})}>Vídeos</Button></Col>}
                  {imovel?.docs?.length > 0 && <Col className="col-auto"><Button className={`button-gallery ${this.state.gallerySelected === 'catalog' ? 'active' : ''}`} onClick={() => this.setState({gallerySelected: 'catalog'})}>Documentos</Button></Col>}
                  {imovel?.progress_photos?.length > 0 && <Col className="col-auto"><Button className={`button-gallery ${this.state.gallerySelected === 'progress_photos' ? 'active' : ''}`} onClick={() => this.setState({gallerySelected: 'progress_photos'})}>Obra - Fotos</Button></Col>}
                  {imovel?.progress_videos?.length > 0 && <Col className="col-auto"><Button className={`button-gallery ${this.state.gallerySelected === 'progress_videos' ? 'active' : ''}`} onClick={() => this.setState({gallerySelected: 'progress_videos'})}>Obra - Vídeos</Button></Col>}
                  {imovel?.plans?.length > 0 && <Col className="col-auto"><Button className={`button-gallery ${this.state.gallerySelected === 'plans' ? 'active' : ''}`} onClick={() => this.setState({gallerySelected: 'plans'})}>Plantas</Button></Col>}
                </Row>
                <Row style={{marginTop:'30px'}}>
                  {this.state.gallerySelected === 'photos' &&
                    (activeDownload ?
                    <Col xs={12}>
                        <div className="grid-enterprise-gallery">
                          {imovel?.photos?.map((photo, index) => (
                            <div
                            key={index}
                            className={`item-gallery ${this.state.galleryItemsSelected.some(item => item.type === 'photo' && item.id === photo.id) ? 'active' : ''}`}
                            style={{backgroundImage:'url(' + photo.small_image + ')'}}
                            onClick={() => this.togglePhotoSelection({ type: 'photo', id: photo.id, url: photo.url })}
                            ></div>
                          ))}
                        </div>
                    </Col>
                    :
                    <Col xs={12}>
                      <div className="grid-enterprise-gallery">
                        {imovel?.photos?.map((photo, index) => (
                          <div
                          key={index}
                          className={`item-gallery ${this.state.galleryItemsSelected.some(item => item.type === 'photo' && item.id === photo.id) ? 'active' : ''}`}
                          style={{backgroundImage:'url(' + photo.small_image + ')'}}
                          onClick={() => this.setState({ isGalleryZoom: true, photoIndex: index, allPhotos: imovel?.photos })}
                          ></div>
                        ))}
                      </div>
                  </Col>)
                  }
                  {this.state.gallerySelected === 'videos' &&
                  (activeDownload ?
                    <Col xs={12}>
                      <div className="grid-enterprise-gallery-video">
                        {imovel?.videos?.map((video, index) => (
                          <div
                            key={index}
                            className={`item-gallery video ${this.state.galleryItemsSelected.some(item => item.type === 'video' && item.id === video.id) ? 'active' : ''}`}
                            onClick={() => this.togglePhotoSelection({ type: 'video', id: video.id, url: video.url })}
                            >
                            <i className="ri-video-line"></i>
                            <small>{video.file_name}</small>
                          </div>
                        ))}
                      </div>
                    </Col>
                    :
                    <Col xs={12}>
                        <div className="grid-enterprise-gallery-video">
                          {imovel?.videos?.map((video, index) => (
                            <div
                            key={index}
                            style={{cursor:'pointer'}}
                            className={`item-gallery video ${this.state.galleryItemsSelected.some(item => item.type === 'video' && item.id === video.id) ? 'active' : ''}`}
                            onClick={() => {
                              setTimeout(() => {
                                window.open(video.url, '_blank')
                              }, 100);
                            }}
                            >
                              <i className="ri-video-line"></i>
                              <small>{video.file_name}</small>
                            </div>
                          ))}
                        </div>
                    </Col>)
                  }
                  {this.state.gallerySelected === 'catalog' &&
                  (activeDownload ?
                    <Col xs={12}>
                      <div className="grid-enterprise-gallery-doc">
                        {imovel?.docs?.map((doc, index) => (
                          <div
                          key={index}
                          className={`item-gallery-doc ${this.state.galleryItemsSelected.some(item => item.type === 'doc' && item.id === doc.id) ? 'active' : ''}`}
                          style={{backgroundImage:'url(' + doc.small_image + ')'}}
                          onClick={() => this.togglePhotoSelection({ type: 'doc', id: doc.id, url: doc.url })}
                          >
                            <i className="ri-file-2-line"></i>
                            <small>{doc.filename}</small>
                            </div>
                        ))}
                      </div>
                    </Col>
                    :
                    <Col xs={12}>
                    <div className="grid-enterprise-gallery-doc">
                      {imovel?.docs?.map((doc, index) => (
                        <div
                        key={index}
                        style={{cursor:'pointer'}}
                        className={`item-gallery-doc ${this.state.galleryItemsSelected.some(item => item.type === 'doc' && item.id === doc.id) ? 'active' : ''}`}
                        onClick={() => {
                          setTimeout(() => {
                            window.open(doc.url, '_blank')
                          }, 100);
                        }}
                        >
                          <i className="ri-file-2-line"></i>
                          <small>{doc.filename}</small>
                        </div>
                      ))}
                    </div>
                </Col>)
                  }
                  {this.state.gallerySelected === 'progress_photos' &&
                  (activeDownload ?
                    <Col xs={12}>
                      <div className="grid-enterprise-gallery">
                        {imovel?.progress_photos?.map((photo, index) => (
                          <div
                          key={index}
                          className={`item-gallery ${this.state.galleryItemsSelected.some(item => item.type === 'progress_photos' && item.id === photo.id) ? 'active' : ''}`}
                          style={{backgroundImage:'url(' + photo.small_image + ')'}}
                          onClick={() => this.togglePhotoSelection({ type: 'progress_photos', id: photo.id, url: photo.url })}
                          ></div>
                        ))}
                      </div>
                    </Col>
                    :
                    <Col xs={12}>
                      <div className="grid-enterprise-gallery">
                        {imovel?.progress_photos?.map((photo, index) => (
                          <div
                          key={index}
                          className={`item-gallery ${this.state.galleryItemsSelected.some(item => item.type === 'photo' && item.id === photo.id) ? 'active' : ''}`}
                          style={{backgroundImage:'url(' + photo.small_image + ')'}}
                          onClick={() => this.setState({ isGalleryZoom: true, photoIndex: index, allPhotos: imovel?.progress_photos })}
                          ></div>
                        ))}
                      </div>
                  </Col>)
                  }
                  {this.state.gallerySelected === 'progress_videos' &&
                  (activeDownload ?
                    <Col xs={12}>
                    <div className="grid-enterprise-gallery-video">
                      {imovel?.progress_videos?.map((video, index) => (
                        <div
                          key={index}
                          className={`item-gallery video ${this.state.galleryItemsSelected.some(item => item.type === 'video' && item.id === video.id) ? 'active' : ''}`}
                          onClick={() => this.togglePhotoSelection({ type: 'progress_videos', id: video.id, url: video.url })}
                          >
                          <i className="ri-video-line"></i>
                          <small>{video.file_name}</small>
                        </div>
                      ))}
                    </div>
                  </Col>
                    :
                    <Col xs={12}>
                        <div className="grid-enterprise-gallery-video">
                          {imovel?.progress_videos?.map((video, index) => (
                            <div
                            key={index}
                            style={{cursor:'pointer'}}
                            className={`item-gallery video ${this.state.galleryItemsSelected.some(item => item.type === 'progress_videos' && item.id === video.id) ? 'active' : ''}`}
                            onClick={() => {
                              setTimeout(() => {
                                window.open(video.url, '_blank')
                              }, 100);
                            }}
                            >
                              <i className="ri-video-line"></i>
                              <small>{video.file_name}</small>
                            </div>
                          ))}
                        </div>
                    </Col>)
                  }
                  {this.state.gallerySelected === 'plans' &&
                  (activeDownload ?
                    <Col xs={12}>
                      <div className="grid-enterprise-gallery">
                        {imovel?.plans?.map((plan, index) => (
                          <div
                          key={index}
                          className={`item-gallery ${this.state.galleryItemsSelected.some(item => item.type === 'plan' && item.id === plan.id) ? 'active' : ''}`}
                          style={{backgroundImage:'url(' + plan.url + ')'}}
                          onClick={() => this.togglePhotoSelection({ type: 'plan', id: plan.id, url: plan.url })}
                          ></div>
                        ))}
                      </div>
                    </Col>
                      :
                      <Col xs={12}>
                        <div className="grid-enterprise-gallery">
                          {imovel?.plans?.map((photo, index) => (
                            <div
                            key={index}
                            className={`item-gallery ${this.state.galleryItemsSelected.some(item => item.type === 'photo' && item.id === photo.id) ? 'active' : ''}`}
                            style={{backgroundImage:'url(' + photo.url + ')'}}
                            onClick={() => this.setState({ isGalleryZoom: true, photoIndex: index, allPhotos: imovel?.plans })}
                            ></div>
                          ))}
                        </div>
                    </Col>)
                  }
                </Row>
                <Row>
                  <Col style={{marginTop:'30px', textAlign:'center'}}>
                  {this.state.galleryItemsSelected.length > 0 ?
                    <Button onClick={this.downloadSelectedItems}>Fazer download</Button>
                    :
                    <Button style={{backgroundColor:'#cdcdcd', cursor:'disabled', color:'#333', border:'none'}} disabled>Fazer download</Button>
                  }
                  </Col>
                </Row>
                </Container>
            </div>
          </div>

          <div onClick={() => this.setState({modal_gallery: false})} className={`catalog-select-modal-overlay ${this.state.modal_gallery ? 'active' : ''}`}></div>

          {this.state.isGalleryZoom ? (
              <Lightbox
              className="teste"
                mainSrc={allPhotos[photoIndex]?.url}
                nextSrc={allPhotos[(photoIndex + 1) % allPhotos.length]?.url}
                prevSrc={
                  allPhotos[(photoIndex + allPhotos.length - 1) % allPhotos.length]?.url
                }
                onCloseRequest={() => this.setState({ isGalleryZoom: false })}
                onMovePrevRequest={() =>
                this.setState({
                    photoIndex: (photoIndex + allPhotos.length - 1) % allPhotos.length
                })
                }
                onMoveNextRequest={() =>
                this.setState({
                    photoIndex: (photoIndex + 1) % allPhotos.length
                })
                }
              />
          ) : null}

          {/* PopUp Google Maps */}
          <Modal
            size="lg"
            centered={true}
            isOpen={this.state.modal_large}
            toggle={() => this.setState({ modal_large: false })}
          >
            <ModalHeader toggle={() => this.setState({ modal_large: false })}>
                Localização aproximada do imóvel
            </ModalHeader>
            <ModalBody>
              <Maps latI={imovel?.latitude} lngI={imovel?.longitude} zoomLevel={13}/>
            </ModalBody>
          </Modal>

          <div id="load" className="load">
            <Spinner className="me-2" color="success" />
          </div>
        </Container>
      </div>
    )
  }
}

export default SingleEnterprise;