import { AvField, AvForm } from 'availity-reactstrap-validation';
import Axios from 'axios';
import moment from 'moment';
import React, { Component } from 'react';
import ReactSelect from 'react-select';
import { Col, Row, Button, ModalFooter, ModalBody, ModalHeader, Modal, Label, FormGroup, InputGroup } from "reactstrap";
import ContractUse from './ContractUse';
import Flatpickr from "react-flatpickr"
import SweetAlert from 'react-bootstrap-sweetalert';


const scheduleHours = [
  {hour: 8, minute: '00'},
  {hour: 8, minute: '30'},
  {hour: 9, minute: '00'},
  {hour: 9, minute: '30'},
  {hour: 10, minute: '00'},
  {hour: 10, minute: '30'},
  {hour: 11, minute: '00'},
  {hour: 11, minute: '30'},
  {hour: 12, minute: '00'},
  {hour: 12, minute: '30'},
  {hour: 13, minute: '00'},
  {hour: 13, minute: '30'},
  {hour: 14, minute: '00'},
  {hour: 14, minute: '30'},
  {hour: 15, minute: '00'},
  {hour: 15, minute: '30'},
  {hour: 16, minute: '00'},
  {hour: 16, minute: '30'},
  {hour: 17, minute: '00'},
  {hour: 17, minute: '30'},
  {hour: 18, minute: '00'},
  {hour: 18, minute: '30'},
  {hour: 19, minute: '00'},
  {hour: 19, minute: '30'},
]

class UnitActionPublic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      acceptContract: false,
      modal_schedule: false,
      newEventType:null,
      newEventClientName:null,
      newEventHour:null,
      newEventDateFormated:null,
      schedule_success:false,
      schedule_error:false,
      diaSelecionado:null,
      mesSelecionada:null,
      hoursOptions:null,
    };
    this.tog_schedule = this.tog_schedule.bind(this)
    this.handleContractClosed = this.handleContractClosed.bind(this);
  }

  tog_schedule(){
    this.setState(prevState => ({
      modal_schedule: !prevState.modal_schedule
    }));
    this.removeBodyCss();
  }

  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  handleContractClosed() {
    this.setState({ acceptContract: false });
  }

  handleContractAccepted() {
    this.setState({acceptContract: false})
    this.setState(prevState => ({
      modal_schedule: !prevState.modal_schedule
    }));
    this.removeBodyCss();

    Axios.post(process.env.REACT_APP_API + '/api/auth/verify-contract', {
      type:localStorage.getItem('access'),
      user_id:localStorage.getItem('id'),
      property_id:this.props.imovel.id
      }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
    .then(resp => {
      this.props.handleVerifyContract(true);
    })
    .catch(resp => {
      this.props.handleVerifyContract(false);
    })
  }

  componentDidUpdate() {
    document.querySelector('.visitTypeBtnContainer')?.addEventListener('click', function(e) {
        for(var i =0; document.querySelectorAll('.visitTypeBtn').length > i; i++){
          document.querySelectorAll('.visitTypeBtn')[i].classList.remove('active')
        }
          e.target.classList.add('active')
      }, false);

      document.querySelector('.hour-container')?.addEventListener('click', function(e) {
        for(var i =0; document.querySelectorAll('.hour').length > i; i++){
          document.querySelectorAll('.hour')[i].classList.remove('active')
        }
          e.target.classList.add('active')
      }, false);
}

generateHoursOption() {
  let hoursOptions = null; // Inicialize como nulo

  if (parseInt(this.state.mesSelecionado) >= parseInt(moment(new Date()).format('MM'))) {
    if (parseInt(this.state.dataSelecionada) === parseInt(moment(new Date()).format('DD'))) {
      if (scheduleHours.filter(hour => parseInt(hour.hour) > (parseInt(moment(new Date()).format('HH')) + 3)).length > 0) {
        hoursOptions = scheduleHours
          .filter(hour => parseInt(hour.hour) > (parseInt(moment(new Date()).format('HH')) + 3))
          .map((hour, index) => (
            <div
              key={index}
              className="hour"
              onClick={() => this.setState({ newEventHour: hour.hour + ":" + hour.minute })}
            >
              {hour.hour}:{hour.minute}
            </div>
          ));
      } else {
        hoursOptions = <p style={{ position: 'absolute' }}>Sem horários disponíveis para esta data.</p>;
      }
    } else if (parseInt(this.state.dataSelecionada) < parseInt(moment(new Date()).format('DD')) && parseInt(this.state.mesSelecionado) > parseInt(moment(new Date()).format('MM'))) {
        hoursOptions = scheduleHours.map((hour, index) => (
          <div
            key={index}
            className="hour"
            onClick={() => this.setState({ newEventHour: hour.hour + ":" + hour.minute })}
          >
            {hour.hour}:{hour.minute}
          </div>
        ));
    } else if (parseInt(this.state.dataSelecionada) < parseInt(moment(new Date()).format('DD'))) {
      hoursOptions = <p style={{ position: 'absolute' }}>Sem horários disponíveis para esta data.</p>;
    } else {
      hoursOptions = scheduleHours.map((hour, index) => (
        <div
          key={index}
          className="hour"
          onClick={() => this.setState({ newEventHour: hour.hour + ":" + hour.minute })}
        >
          {hour.hour}:{hour.minute}
        </div>
      ));
    }
  } else {
    hoursOptions = <p style={{ position: 'absolute' }}>Sem horários disponíveis para esta data.</p>;
  }

  // Defina hoursOptions no estado
  this.setState({ hoursOptions });
}

  render(){
    const { premium } = this.props;
    const locale = {
      weekdays: {
        shorthand: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
        longhand: [
          'Domingo',
          'Segunda-feira',
          'Terça-feira',
          'Quarta-feira',
          'Quinta-feira',
          'Sexta-feira',
          'Sábado'
        ]
      },
      months: {
        shorthand: [
          'Jan',
          'Fev',
          'Mar',
          'Abr',
          'Mai',
          'Jun',
          'Jul',
          'Ago',
          'Set',
          'Out',
          'Nov',
          'Dez'
        ],
        longhand: [
          'Janeiro',
          'Fevereiro',
          'Março',
          'Abril',
          'Maio',
          'Junho',
          'Julho',
          'Agosto',
          'Setembro',
          'Outubro',
          'Novembro',
          'Dezembro'
        ]
      },
      firstDayOfWeek: 0,
      rangeSeparator: ' até ',
      weekAbbreviation: 'Sem',
      scrollTitle: 'Rolagem para aumentar',
      toggleTitle: 'Clique para alternar',
      amPM: ['AM', 'PM'],
      yearAriaLabel: 'Ano',
      time_24hr: true
    };
    return(
      <>
      <div id="actionUnitContainer" style={{minWidth:'300px'}}>
      <ContractUse
        acceptContract={this.state.acceptContract}
        onContractClosed={this.handleContractClosed}
        onContractAccept={this.handleContractAccepted.bind(this)}
        premium={premium}
        imovel={this.props.imovel}
      />
      <Row>
        <Col>
        {this.props.imovel.sell_price ?
        <>
          <div className="offer"><h3>{(parseFloat(this.props.imovel.sale_value) / 100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</h3></div>
          <div className="price"><h3>{(parseFloat(this.props.imovel.sell_price) / 100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</h3></div>
        </>
        :
        <div className="price"><h3>{(parseFloat(this.props.imovel.sale_value) / 100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</h3></div>
        }
        </Col>
      </Row>
      <Row>
        <Col xs={12} style={{margin:'0 0 10px'}}>
          <p style={{fontSize:'18px', fontWeight:'600'}}>Condições de pagamento</p>
        </Col>
      </Row>
        <Row>
          <Col xs={3} style={{fontWeight:'700', fontSize:'12px'}}>Condição</Col>
          <Col xs={2} style={{fontWeight:'700', fontSize:'12px'}}>Parcelas</Col>
          <Col xs={3} style={{fontWeight:'700', fontSize:'12px'}}>Valor</Col>
          <Col xs={4} style={{fontWeight:'700', fontSize:'12px'}}>Observação</Col>
        </Row>
        {this.props.imovel?.payment?.map((pay, index) => (
          <Row key={index} className={index % 2 === 0 ? "grey-background unitItem" : "white-background unitItem"}>
            <Col xs={3}>{pay.type === 'entrance' ? 'Entrada': pay.type === 'reinforcement' ? 'Reforços' : pay.type === 'parcels' ? 'Parcelamento' : 'Entrega das chaves'}</Col>
            <Col xs={2}>{pay.parcels}</Col>
            <Col xs={3}>{
            pay.type_value === 'currency' ?
            parseInt(pay.value).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
            :
            pay.value / 100 + '%'
            }</Col>
            <Col xs={4}>{pay.observation}</Col>
          </Row>
        ))}


    </div>
    <div id="actionUnitContainer" style={{marginTop:'20px'}}>
                    <Row id="broker">
          <Col xs={12}>
            <div className="image" style={{backgroundImage:'url(' + this.props.user?.profile_photo + ')'}}></div>
          </Col>
          <Col xs={12}>
            <h3>{this.props.user.name}</h3>
            <p>CRECI: <b>{this.props.user.creci}</b></p>
          </Col>
          <Col>
            <div className="phone"><i className="ri-phone-fill"></i><p>{this.props.user?.phone}</p></div>
            <div className="email"><i className="ri-mail-fill"></i><p>{this.props.user?.email}</p></div>
          </Col>
        </Row>
        <Row style={{marginTop:'15px'}}>
          <Button type="button" className="waves-effect waves-light me-1" onClick={() => {
                setTimeout(() => {
                  window.open(`https://api.whatsapp.com/send?phone=55` + (this.props.user.phone ? this.props.user.phone : '4741081865' )?.replace('(', '').replace(')', '').replace('-', '').replace(/ /g, "") + `&text=Olá,%20tenho%20interesse%20no%20` + this.props.imovel?.title + " (" + this.props.imovel.sku + ')', "_blank")
                }, 300);
              }}>
            Chamar no Whatsapp <i className="ri-whatsapp-line align-middle ms-2"></i>
          </Button>
        </Row>
        </div>
        </>
    )
  }
}

  export default UnitActionPublic;