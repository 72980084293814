import React, { Component } from 'react';
import { Col, Dropdown, DropdownMenu, DropdownToggle, DropdownItem, Card, CardBody, Row, Container, Input, Label, Badge, Spinner, Button, ModalHeader, ModalBody, Modal } from "reactstrap";
import { Link } from "react-router-dom";
import Axios from 'axios'

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Breadcrumbs from "../../components/Common/Breadcrumb"
import styles from '../../pages/Home/styles/Home.module.css';
import { NewUserModal } from './../ConstructionUsers/components/newUserModal';

import './../../assets/scss/custom/components/_editProperties.scss'

import moment from 'moment';
import ReactSelect from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import PhoneInput from '../Home/components/PhoneInput';
import EmailInput from '../Home/components/EmailInput';
import CpfInput from '../Home/components/CpfInput';
import CepInput from '../Home/components/CepInput';
import CnpjInput from '../Home/components/CnpjInput';
require('moment/locale/pt.js');

class editUsersAdm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users:[],
      filteredUsers:[],
      statusFilter:'Todos',
      typeFilter:'Todos',
      filterUsers:[],
      planFilter:'Todos',
      search:'',
      confirm_both: false,
      success_dlg: false,
      error_dlg:false,
      dynamic_title: "",
      dynamic_description: "",
      nivel:2,
      delete_id:"",
      type_user_id:"",
      delete_sku:"",
      modalMap:false,
      modalAdm:false,
      name:'',
      phone:'',
      cpf:'',
      cnpj:'',
      email:'',
      confirmPassword:'',
      password:'',
      emailIsExist:null,
      cpfIsExist:null,
      cnpjIsExist:null,
      endereco:{},
      isMobile:false,
      type_owner:0,
      cep:'',
      registerError:false,
      registerSuccess:false,
      breadcrumbItems: [
        { title: "Fast Sale", link: "/dashboard" },
        { title: "Editar usuários", link: "#" },
    ],
    }
  }


  componentDidMount(){
          document.getElementById('load').classList.add('active')

          Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
            type: localStorage.getItem('access'),
            user_id: localStorage.getItem('id'),
            }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(response => {


              if(localStorage.getItem('access') === 'A'){

              Axios.get(process.env.REACT_APP_API + '/api/auth/all-users',
              {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
              .then(response => {
                this.setState({users: response.data})
                this.applyFilters();

                setTimeout(() => {
                  document.getElementById('load').classList.remove('active')
                }, 300);
              })

            }else{
              setTimeout(() => {
                window.open("/dashboard", '_parent')
              }, 300);
            }

            })
          .catch(response =>{
            localStorage.removeItem("history_url")
            setTimeout(() => {
              window.open("/login", '_parent')
            }, 300);
          })
  }

  handleStatusChange = (event) => {
    this.setState({ statusFilter: event.target.value }, () => {
      this.applyFilters();
    });
  };

  handleTypeChange = (event) => {
    this.setState({ typeFilter: event.target.value }, () => {
      this.applyFilters();
    });
  };

  handlePlanChange = (event) => {
    this.setState({ planFilter: event.target.value }, () => {
      this.applyFilters();
    });
  };

  applyFilters = () => {
    const { users, statusFilter, typeFilter, planFilter } = this.state;
  
    let filteredUsers = users;
  
    // Aplicar filtro de status
    if (statusFilter !== 'Todos') {
      filteredUsers = filteredUsers.filter((imovel) => imovel.status === parseInt(statusFilter));
    }else if (statusFilter === 'Todos') {
      filteredUsers = users;
    }

    // Aplicar filtro de tipo
    if (typeFilter !== 'Todos') {
      filteredUsers = filteredUsers.filter((imovel) => imovel.type === typeFilter);
    }else if (typeFilter === 'Todos') {
      filteredUsers = filteredUsers;
    }

    // Aplicar filtro de plano
    if (planFilter === '0') {
      filteredUsers = filteredUsers.filter((imovel) => imovel.premium === 0);
    }else if (planFilter === '2') {
        filteredUsers = filteredUsers.filter((imovel) => imovel.premium === 2);
    }else if (planFilter === '1') {
      filteredUsers = filteredUsers.filter((imovel) => imovel.premium === 1);
    }else if (planFilter === 'Todos') {
      filteredUsers = filteredUsers;
    }

    setTimeout(() => {
      this.setState({ filteredUsers: filteredUsers, filterUsers: filteredUsers });
    }, 0);
  };

  openModal = () => this.setState({modalMap : !this.state.modalMap});
  openAdmModal = () => this.setState({modalAdm : !this.state.modalAdm});

  handlePhoneChange = (phoneValue) => {
    this.setState({phone: phoneValue});
  };

  handleEmailChange = (newEmail) => {
    this.setState({email:newEmail});
  };

  handleEmailExists = (isExistValue) => {
    this.setState({emailIsExist: isExistValue});
  };

  handleCpfExists = (isExistValue) => {
    this.setState({cpfIsExist: isExistValue});
  };

  handleCpfChange = (value) => {
    this.setState({cpf: value});
  };

  handleCnpjExists = (isExistValue) => {
    this.setState({cnpjIsExist: isExistValue});
  };

  handleCnpjChange = (value) => {
    this.setState({cnpj: value});
  };

  handleCepChange = (value) => {
    this.setState({cep: value});
  };

  handleEnderecoChange = (value) => {
    this.setState({endereco: value});
  };


  createRegister = () => {
    if (
      this.state.name === '' ||
      this.state.phone === '' ||
      this.state.email === '' ||
      this.state.cep === '' ||
      this.state.password === '' ||
      this.state.confirmPassword === ''
    ) {
      this.setState({registerError: true})
      return;
    }

   if (this.state.password !== this.state.confirmPassword) {
    this.setState({registerError: true})
     return;
   }

   document.getElementById('load').classList.add('active');
   
     const data = new FormData();
     data.append('name', this.state.name);
     data.append('phone', this.state.phone);
     data.append('email', this.state.email);
     if(this.state.type_owner == 1){
      data.append('cpf', this.state.cpf);
     }
     if(this.state.type_owner == 2){
      data.append('cnpj', this.state.cnpj);
     }
     data.append('cep', this.state.cep);
     data.append('street', this.state.endereco.logradouro)
     data.append('district', this.state.endereco.bairro)
     data.append('complement', this.state.endereco.complemento)
     data.append('city', this.state.endereco.cidade)
     data.append('state', this.state.endereco.estado)
     data.append('password', this.state.password);
     data.append('status', 1)

     Axios.post(
       process.env.REACT_APP_API + '/api/register/owners',
       data,
       { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } }
     )
       .then((response) => {
        this.setState({registerSuccess: true})
        this.setState({modalMap : !this.state.modalMap})
         document.getElementById('load').classList.remove('active');
       })
       .catch((error) => {
        this.setState({registerError: true})
        document.getElementById('load').classList.remove('active');
       });
   } 

   createAdm = () => {
    if (
      this.state.name === '' ||
      this.state.email === '' ||
      this.state.password === '' ||
      this.state.confirmPassword === ''
    ) {
      this.setState({registerError: true})
      return;
    }

   if (this.state.password !== this.state.confirmPassword) {
    this.setState({registerError: true})
     return;
   }

   document.getElementById('load').classList.add('active');
   
     const data = new FormData();
     data.append('name', this.state.name);
     data.append('email', this.state.email);
     data.append('password', this.state.password);
     data.append('level', this.state.nivel)
     data.append('premium', 1)
     data.append('status', 1)

     Axios.post(
       process.env.REACT_APP_API + '/api/register/users',
       data,
       { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } }
     )
       .then((response) => {
        this.setState({registerSuccess: true})
        this.setState({modalMap : !this.state.modalAdm})
         document.getElementById('load').classList.remove('active');
       })
       .catch((error) => {
        console.log(error)
        this.setState({registerError: true})
        document.getElementById('load').classList.remove('active');
       });
   } 


  render() {
    const data = {
      columns: [
        {
          dataField: "status",
          text: "Status"
        },
        {
          dataField: 'type_user',
          text: 'Tipo'
        },
        {
          dataField: "photo",
          text: "Foto"
        },
        {
          dataField: "name",
          text: "Nome"
        },
        {
          dataField: "created_at",
          classes:"formatDate",
          text: "Cadastro"
        },
        {
          dataField: "premium",
          text: "Plano"
        },
        {
          dataField: "properties",
          text: "Captações"
        },
        {
          dataField: "edit",
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => { 
              setTimeout(() => {
                 window.open("/editar-perfil?id=" + row.id + '&type=' + (row.type_user === 'Corretor' ? 'C' : row.type_user === 'Imobiliária' ? 'I' : row.type_user === 'Proprietário' ? 'P' : row.type_user === 'Construtora' ? 'T' : row.type_user === 'Construtora Extra' ? 'S' : 'A'), '_parent')
              }, 300);
             },
          },
          text: "Editar"
        },
        {
          dataField: "delete",
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => { 
              if(localStorage.getItem('level') !== '2'){
                this.setState({ confirm_both: true })
                this.setState({ delete_id: row.id })
                this.setState({ type_user_id: row.type_user })
              }
            },
          },
          text: "Excluir"
        },
      ],
    };

    const {users, filteredUsers, filterUsers} = this.state;


    // const selectRow = {
    //   mode: 'checkbox',
    //   clickToSelect: true
    // };

    const usersObject = []

    {filteredUsers.sort(function(a,b){
      return new Date(b.created_at) - new Date(a.created_at)}).map(user => {
      const novoUser = {
        id: user?.id,
        edit: <i className="ri-edit-line"></i>,
        delete: <i className="ri-delete-bin-6-line"></i>,
        status: user?.status === 1 ? <i className="ri-checkbox-blank-circle-fill" style={{color:'#3E5E3F'}}></i>
        : user?.status === 2 ? <i className="ri-checkbox-blank-circle-fill" style={{color:'#f1ce41'}}></i>
        : user?.status === 3 ? <i className="ri-checkbox-blank-circle-fill" style={{color:'#333'}}></i>
        : user?.status === 5 ? <i className="ri-checkbox-blank-circle-fill" style={{color:'#628adc'}}></i>
        : <i className="ri-checkbox-blank-circle-fill" style={{color:'#da2c3e'}}></i>,
        type_user: user?.type === 'C' ? 'Corretor' : user?.type === 'I' ? 'Imobiliária' : user?.type === 'P' ? 'Proprietário' : user?.type === 'T' ? 'Construtora' : user?.type === 'S' ? 'Construtora Extra' : 'Administrador',
        premium: user?.status !== 5 ? (user?.premium === 0 ? <div style={{backgroundColor: '#cdcdcd', color: '#333', borderRadius:'5px', display:'flex', alignItem:"center", justifyContent:'center'}}>Free</div>
        : user?.premium === 1 ? <div style={{backgroundColor: '#3E5E3F', color: '#fff', borderRadius:'5px', display:'flex', alignItem:"center", justifyContent:'center'}}>Premium</div>
        : user?.premium === 2 ? <div style={{backgroundColor: '#4582a1', color: '#fff', borderRadius:'5px', display:'flex', alignItem:"center", justifyContent:'center'}}>Start</div>
        : user?.premium === 'admin' ? <div style={{backgroundColor: '#050505', color: '#fff', borderRadius:'5px', display:'flex', alignItem:"center", justifyContent:'center'}}>Criar e editar</div>
        : user?.premium === 'view' ? <div style={{backgroundColor: '#050505', color: '#fff', borderRadius:'5px', display:'flex', alignItem:"center", justifyContent:'center'}}>Visualizar</div>
        : <></>) : <div style={{backgroundColor: '#4582a1', color: '#fff', borderRadius:'5px', display:'flex', alignItem:"center", justifyContent:'center'}}>Lead</div>,
        name: user?.name,
        photo: <div style={{backgroundColor:'#f4f4f4', backgroundImage:'url(' + user?.profile_photo + ')', backgroundSize:'cover', backgroundPosition:'center center', width:'40px', height: '40px', borderRadius:'50%'}}></div>,
        properties: (user?.n_props ? user?.n_props : '0') + "/" + (user?.p_count ? user?.p_count : '0'),
        created_at: moment(user?.created_at).format('DD/MM/YYYY'),
        email: user?.email
      }
      usersObject.push(novoUser); 
    })}

    const options = {
      // pageStartIndex: 0,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: false,
      sizePerPageList:
        [{
          text: '5 resultados p/ página', value: 5
        }, {
          text: '10 resultados p/ página', value: 10
        }, {
          text: 'Todos usuários', value: usersObject.length
        }]

    };


    return (
        <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Editar usuários" breadcrumbItems={this.state.breadcrumbItems} />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row style={{justifyContent:'space-between'}}>
                    <Col className="col-auto">
                      <h4 className="card-title mb-4">Gestão de usuários</h4>
                    </Col>  
                    <Col className="col-auto">
                      <p className="text-muted">{usersObject.length} resultados.</p>
                    </Col>
                  </Row>
                  <Row style={{justifyContent:'flex-end', marginBottom: '10px'}}>
                    <Col xs={12} md={6} lg={2} className="filterButtonContainer" style={{display:'flex', justifyContent:'flex-end', gap:'10px', alignItems:'center'}}>
                      <Label>Plano:</Label>
                      <select className="form-control" onChange={this.handlePlanChange}>
                        <option value="Todos">Todos</option>
                        <option value={0}>Free</option>
                        <option value={1}>Premium</option>
                        <option value={2}>Start</option>
                      </select>
                    </Col>
                    <Col xs={12} md={6} lg={2} className="filterButtonContainer" style={{display:'flex', justifyContent:'flex-end', gap:'10px', alignItems:'center'}}>
                      <Label>Status:</Label>
                      <select className="form-control" onChange={this.handleStatusChange}>
                        <option value="Todos">Todos</option>
                        <option value={1}>Ativos</option>
                        <option value={0}>Inativos</option>
                        <option value={2}>Pendentes</option>
                        <option value={3}>Pendentes (docs)</option>
                        <option value={5}>Leads</option>
                      </select>
                    </Col>
                    <Col  xs={12} md={6} lg={2} className="filterButtonContainer" style={{display:'flex', justifyContent:'flex-end', gap:'10px', alignItems:'center'}}>
                      <Label>Tipo:</Label>
                      <select className="form-control" onChange={this.handleTypeChange}>
                        <option value="Todos">Todos</option>
                        <option value="C">Corretor</option>
                        <option value="I">Imobiliária</option>
                        <option value="T">Construtora</option>
                        <option value="S">Construtora Extra</option>
                        <option value="P">Proprietário</option>
                        <option value="A">Administrador</option>
                      </select>
                    </Col>
                    <Col xs={12} md={6} lg={2} className="filterButtonContainer" style={{display:'flex', flexDirection:'row', maxWidth: '240px'}}>
                      <Input
                      placeholder="Buscar nome"
                      onChange={(e) => this.setState({filteredUsers: filterUsers.filter(user => 
                      user?.name?.toLowerCase().includes(e.target.value.toLowerCase())
                      || 
                      user?.email?.toLowerCase().includes(e.target.value.toLowerCase())
                      || 
                      user?.phone?.toLowerCase().includes(e.target.value.toLowerCase())
                      )})}></Input>
                    </Col>
                    </Row>
                    {localStorage.getItem('level') !== '2' &&
                    <Row style={{justifyContent:'flex-end', marginBottom: '10px'}}>
                      <Col className="col-auto" style={{display:'flex', justifyContent:'flex-end'}}>
                        <Button onClick={this.openAdmModal}>Novo Adm</Button>
                      </Col>
                      <Col className="col-auto" style={{display:'flex', justifyContent:'flex-end'}}>
                        <Button onClick={() => this.setState({new_sub_user: true})}>Novo Usuário Construtora</Button>
                      </Col>
                      <Col className="col-auto" style={{display:'flex', justifyContent:'flex-end'}}>
                        <Button onClick={this.openModal}>Novo proprietário</Button>
                      </Col>
                    </Row>
                    }
                  <div className="table-responsive">

                  <BootstrapTable
                    keyField='id'
                    data={usersObject}
                    columns={data.columns}
                    // expandRow={expandRow}
                    pagination={paginationFactory(options)}
                    // selectRow={false}
                  />
                  </div>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {this.state.confirm_both ? (
          <SweetAlert
            title="Atenção!"
            warning
            showCancel
            confirmBtnText="Tenho certeza"
            cancelBtnText="Cancelar"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() =>{
              console.log(this.state.type_user_id)
              if(this.state.type_user_id === 'Corretor'){
                Axios.post(process.env.REACT_APP_API + '/api/auth/brokers-delete/' +  this.state.delete_id, {},
                {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                .then(response => {
                  this.setState({
                    confirm_both: false,
                    success_dlg: true,
                    dynamic_title: "Deletado",
                    dynamic_description: "O corretor foi deletado."
                  })
                })
              }
              if(this.state.type_user_id === 'Imobiliária'){
                Axios.post(process.env.REACT_APP_API + '/api/auth/real-estates-delete/' +  this.state.delete_id, {},
                {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                .then(response => {
                  this.setState({
                    confirm_both: false,
                    success_dlg: true,
                    dynamic_title: "Deletado",
                    dynamic_description: "A imobiliária foi deletado."
                  })
                })
              }
              if(this.state.type_user_id === 'Proprietário'){
                Axios.post(process.env.REACT_APP_API + '/api/auth/owners-delete/' +  this.state.delete_id, {},
                {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                .then(response => {
                  this.setState({
                    confirm_both: false,
                    success_dlg: true,
                    dynamic_title: "Deletado",
                    dynamic_description: "O proprietário foi deletado."
                  })
                })
              }
              if(this.state.type_user_id === 'Administrador'){
                Axios.post(process.env.REACT_APP_API + '/api/auth/users-delete/' +  this.state.delete_id, {},
                {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                .then(response => {
                  this.setState({
                    confirm_both: false,
                    success_dlg: true,
                    dynamic_title: "Deletado",
                    dynamic_description: "O administrador foi deletado."
                  })
                })
              }
              if(this.state.type_user_id === 'Construtora'){
                Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/constructions-delete/' +  this.state.delete_id, {},
                {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                .then(response => {
                  this.setState({
                    confirm_both: false,
                    success_dlg: true,
                    dynamic_title: "Deletado",
                    dynamic_description: "A construtora foi deletada."
                  })
                })
              }
              if(this.state.type_user_id === 'Construtora Extra'){
                Axios.post(process.env.REACT_APP_API + '/api/auth/delete-subuser/' +  this.state.delete_id, {},
                {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                .then(response => {
                  this.setState({
                    confirm_both: false,
                    success_dlg: true,
                    dynamic_title: "Deletado",
                    dynamic_description: "O usuário foi deletado."
                  })
                })
              }
            }}

            onCancel={() =>
              this.setState({
                confirm_both: false,
                error_dlg: true,
                dynamic_title: "Cancelado",
                dynamic_description: "O usuário ainda está cadastrado."
              })
            }
          >
            <p className="alertText">Você tem certeza que deseja deletar o usuário <b>{this.state.delete_sku}</b>?</p>
          </SweetAlert>
        ) : null}
          {this.state.success_dlg ? (
            <SweetAlert
              success
              title={this.state.dynamic_title}
              onConfirm={() => {
                this.setState({ success_dlg: false })
                window.location.reload()
              }}
            >
              {this.state.dynamic_description}
            </SweetAlert>
          ) : null}

          {this.state.error_dlg ? (
            <SweetAlert
              error
              title={this.state.dynamic_title}
              onConfirm={() => this.setState({ error_dlg: false })}
            >
              {this.state.dynamic_description}
            </SweetAlert>
          ) : null}
        <div id="load" className="load">
          <Spinner className="me-2" color="success" />
        </div>




        <Modal isOpen={this.state.modalMap} toggle={this.openModal} className={
          !this.state.isMobile ? "modal-dialog-centered" : "modal-fullscreen"
          } size='lg'>
            <ModalHeader toggle={this.openModal}>
              
            </ModalHeader>
            <ModalBody>
              <div>
              <div className={styles.modal_title}>Preencha todos os campos para concluir o cadastro!</div>
                <div className={styles.container_input}>
                  <div className={styles.container_inputs_modal}>
                    <label>Nome</label>
                    <input 
                      type="text" 
                      value={this.state.name}
                      onChange={(e) => this.setState({name: e.target.value})}
                      required
                    />
                  </div>
                  <div className={styles.container_inputs_modal}>
                    <label className={styles.input_phone}>Telefone</label>
                    <PhoneInput phoneNumber={this.state.phone} onPhoneChange={this.handlePhoneChange} />
                  </div>
                  <div className={styles.container_inputs_modal}>
                    <label className={styles.input_phone}>Email</label>
                    <EmailInput onEmailChange={this.handleEmailChange} onExists={this.handleEmailExists}/>
                  </div>
                  <div className={styles.container_inputs_modal} style={{width:'100%'}}>
                    <label className={styles.input_phone}>Tipo de pessoa:</label>
                      <select className="form-control" onChange={(e) => this.setState({type_owner: e.target.value})}>
                        <option value={0}>Selecione</option>
                        <option value={1}>Pessoa física</option>
                        <option value={2}>Pessoa jurídica</option>
                      </select>
                  </div>
                  {this.state.type_owner == 1 &&
                    <div className={styles.container_inputs_modal}>
                      <div className={styles.container_inputs}>
                        <label className={styles.input_phone}>CPF</label>
                        <CpfInput onExists={this.handleCpfExists} onCpfChange={this.handleCpfChange} />
                      </div>
                    </div>
                  }
                  {this.state.type_owner == 2 &&
                    <div className={styles.container_inputs_modal}>
                      <div className={styles.container_inputs}>
                        <label className={styles.input_phone}>CNPJ</label>
                        <CnpjInput onExists={this.handleCnpjExists} onCnpjChange={this.handleCnpjChange} />
                      </div>
                    </div>
                  }
                  <div className={styles.container_inputs_modal}>
                      <label className={styles.input_phone}>CEP</label>
                      <CepInput onCepChange={this.handleCepChange} onEnderecoChange={this.handleEnderecoChange} />
                  </div>
                  <div className={styles.container_inputs_modal}>
                    <label className={styles.input_phone}>Crie sua senha</label>
                    <input 
                        type="password" 
                        value={this.state.password}
                        onChange={(e) => this.setState({password: e.target.value})}
                        required
                    />
                  </div>
                  <div className={styles.container_inputs_modal}>
                    <label className={styles.input_phone}>Confirme sua senha</label>
                    <input
                        type="password" 
                        value={this.state.confirmPassword}
                        onChange={(e) => this.setState({confirmPassword : e.target.value})}
                        required
                    />
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '40px' }}>
                {this.state.emailIsExist === '' ?
                <Button
                  id="finalizar-cadastro" 
                  className={styles.button_confirm}
                  color="success" onClick={this.createRegister}
                >
                  Cadastrar
                </Button>
                :
                <Button
                  id="finalizar-cadastro" 
                  className={styles.button_confirm}
                  color="success"
                  disabled
                >
                  Cadastrar
                </Button>
                }

              </div>
            </ModalBody>
          </Modal>

          <Modal isOpen={this.state.modalAdm} toggle={this.openAdmModal} className={
          !this.state.isMobile ? "modal-dialog-centered" : "modal-fullscreen"
          } size='lg'>
            <ModalHeader toggle={this.openAdmModal}>
              
            </ModalHeader>
            <ModalBody>
              <div>
              <div className={styles.modal_title}>Preencha todos os campos para concluir o cadastro!</div>
                <div className={styles.container_input}>
                  <div className={styles.container_inputs_modal}>
                    <label>Nome</label>
                    <input 
                      type="text" 
                      value={this.state.name}
                      onChange={(e) => this.setState({name: e.target.value})}
                      required
                    />
                  </div>
                  <div className={styles.container_inputs_modal}>
                    <label className={styles.input_phone}>Email</label>
                    <EmailInput onEmailChange={this.handleEmailChange} onExists={this.handleEmailExists}/>
                  </div>
                  <div className={styles.container_inputs_modal} style={{width:'100%'}}>
                    <label className={styles.input_phone}>Permissão:</label>
                      <select className="form-control" onChange={(e) => this.setState({nivel: e.target.value})}>
                        <option value={2}>Comercial</option>
                        <option value={1}>Operacional</option>
                      </select>
                  </div>
                  <div className={styles.container_inputs_modal}>
                    <label className={styles.input_phone}>Crie sua senha</label>
                    <input 
                        type="password" 
                        value={this.state.password}
                        onChange={(e) => this.setState({password: e.target.value})}
                        required
                    />
                  </div>
                  <div className={styles.container_inputs_modal}>
                    <label className={styles.input_phone}>Confirme sua senha</label>
                    <input
                        type="password" 
                        value={this.state.confirmPassword}
                        onChange={(e) => this.setState({confirmPassword : e.target.value})}
                        required
                    />
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '40px' }}>
                {this.state.emailIsExist === '' ?
                <Button
                  id="finalizar-cadastro" 
                  className={styles.button_confirm}
                  color="success" onClick={this.createAdm}
                >
                  Cadastrar
                </Button>
                :
                <Button
                  id="finalizar-cadastro" 
                  className={styles.button_confirm}
                  color="success"
                  disabled
                >
                  Cadastrar
                </Button>
                }

              </div>
            </ModalBody>
          </Modal>


          {this.state.registerError && (
            <SweetAlert 
              warning 
              onConfirm={() => this.setState({registerError :false})}
            >
              {this.state.password !== this.state.confirmPassword &&  <p>Sua senha e confirmação da senha não coincidem.</p>}
              {(this.state.email === '' || this.state.phone === '' || this.state.name === '' || this.state.cep === '') &&  <p>Por favor, preencha todos os campos.</p>}
            </SweetAlert>
          )}

          {this.state.registerSuccess && (
            <SweetAlert
              success
              confirmBtnText="Ok"
              onConfirm={() => {this.setState({registerSuccess: false})}}
            >
              <p style={{ fontWeight: 600, fontSize: '1.2rem' }}>Cadastro realizado com sucesso!</p>
            </SweetAlert>
          )}

        <NewUserModal handleUpdate={() => {
          setTimeout(() => {
            window.location.reload()
          }, 0);
        }} adm={true} open={this.state.new_sub_user} onClose={() => this.setState({new_sub_user: false})}/>
      </div>
    );
  }
}

export default editUsersAdm;