import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Component } from "react";
import Axios from 'axios';
import { Link } from "react-router-dom";
import Select from "react-select";
import { Button, Card, CardBody, Col, Container, Form, Input, Label, Modal, ModalBody, ModalHeader, Progress, Row, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import CurrencyInput from "../../components/Inputs/CurrencyInput";
import Dropzone, { useDropzone } from 'react-dropzone';
import { Draggable } from "react-drag-reorder";
import EnterprisePhotosOrder from '../editProperties/enterprisePhotosOrder';
import EnterprisePlansOrder from "../editProperties/enterprisePlansOrder";

const id = window.location.search.replace('?id=', '')

class EnterprisePlans extends Component {
    constructor(props) {
      super(props);

      this.state = {
        breadcrumbItems: [
          { title: "Fast Sale", link: "/dashboard" },
          { title: "Empreendimentos", link: "#" },
      ],
      enterprise:[],
      plansOrder:null,
      dragPhotos:'',
      plansArray:[],
      selectedPlans: [],
      progressBars: [],
      deleteSuccess:false,
      errors:[],
      deleteError:false,
      count:0,
      sendImageError:false,
      }
      this.updateEnterprise = this.updateEnterprise.bind(this);
      this.updatePositions = this.updatePositions.bind(this);
      this.deleteImage = this.deleteImage.bind(this);
    }


    componentDidMount(){
      Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
        type: localStorage.getItem('access'),
        user_id: localStorage.getItem('id'),
        }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {
        Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprises/' + id, {
           headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
         }).then(response => {
            this.setState({
                enterprise: response.data,
                skillsEditList: response.data.details
            })
         })
        })
         .catch(response =>{
          setTimeout(() => {
              window.open("/login", '_parent')
          }, 300);
          })  
    }

      formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
      };

      handleFormDataPlans = (orders, update) => {
        const data = new FormData();
        for(let i = 0; i < update.length; i++){
          data.append('position[]', orders[i])
          data.append('id[]', update[i])
        }
        this.setState({ plansOrder: data });
      };
      
      handleAcceptedPlans = files => {
        const acceptedFiles = [];
        files.forEach(file => {
            if (file.size <= 8 * 1024 * 1024) {
                acceptedFiles.push(file);
            } else {
                this.setState(prevState => ({
                    errors: [...prevState.errors, { name: file.name, message: 'Tamanho excede 8MB' }]
                }));
            }
        });

        files.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: this.formatBytes(file.size)
          })
        );
    
        this.setState({ selectedPlans: files }, () => {
          this.setState({plansArray: this.state.selectedPlans.map((file, index) => ({
            file: file,
            position: index
          }))});
        });

        this.updateEnterprise()
      };

      updateEnterprise(event) {
          document.getElementById('load').classList.add('active')
          const { plansArray } = this.state;
          const totalPhotos = plansArray.length;
          this.setState({ progressBars: new Array(totalPhotos).fill(0) });

          if(this.state.plansArray.length > 0){

            plansArray.forEach((photo, index) => {
              const dataEnterprisePhotos = new FormData();
              dataEnterprisePhotos.append(`plan[0][file]`, photo.file);
              dataEnterprisePhotos.append(`plan[0][position]`, photo.position);
              dataEnterprisePhotos.append(`plan[0][property_enterprise_id]`, id);

              setTimeout(() => {
                Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprise-plans', dataEnterprisePhotos, {
                  headers: { "Authorization": "Bearer " + localStorage.getItem('token') },
                  onUploadProgress: event => {
                      const progress = Math.round(100 * event.loaded) / event.total;
                      const updatedProgressBars = [...this.state.progressBars];
                      updatedProgressBars[index] = progress;
                      this.setState({ progressBars: updatedProgressBars }, () => {
                        this.forceUpdate();
                    });
                  }
              }).then(response => {
                  if (index === totalPhotos - 1) {
                      document.getElementById('load').classList.remove('active')

                      Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprises/' + id, {
                        headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
                      }).then(response => {
                        this.setState({
                            enterprise: response.data,
                        })
                        this.setState({count : this.state.count + 1})
                        this.setState({plansArray : [], selectedPlans: []})
                      })
                  }
              }).catch(error => {
                const updatedErrors = [...this.state.errors];
                updatedErrors[index] = { isError: true, message: 'Erro no envio da imagem' };
                this.setState({ errors: updatedErrors });
                this.setState({sendImageError: true})
              });
              }, 500);

            });
          }
    }

    updatePositions(){
      if(localStorage.getItem('access') === 'S' && localStorage.getItem('construction_role') === 'view'){
        this.setState({error_auth:true})
      }else{
      document.getElementById('load').classList.add('active')
      Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/update-plans', this.state.plansOrder, {
          headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
        }).then(response => {
          setTimeout(() => {
            window.open('./../perfil-empreendimento?id=' + id, '_parent')
        }, 100);
        }).catch(response => {
          setTimeout(() => {
            window.open('./../perfil-empreendimento?id=' + id, '_parent')
        }, 100);
        })
      }
    }

    deleteImage(photo_id) {
      if(localStorage.getItem('access') === 'S' && localStorage.getItem('construction_role') === 'view'){
        this.setState({error_auth:true})
      }else{
      Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprise-plan/delete/' + photo_id, {},
      {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {
          this.setState({deleteSuccess: true})
  
          Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprises/' + id, {
            headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
          }).then(response => {
             this.setState({
                 enterprise: response.data,
             })
             this.setState({count : this.state.count + 1})
          })
        })              
        .catch(response => {
          this.setState({deleteError: true})
        })
      }
     }

    render(){
        const {enterprise} = this.state;

        return(
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title={enterprise?.title} breadcrumbItems={this.state.breadcrumbItems} />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <div className="row justify-content-center">
                                        <AvForm className="cardBody needs-validation" ref={this.validateRef}>
                                        <Container style={{paddingBottom:'20px', paddingTop:'20px'}}>
                                          <Row style={{margin:'10px 0'}}>
                                            <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Plantas do empreendimento</h5></Col>
                                          </Row>
                                          <Form>
                                          {!(localStorage.getItem('access') === 'S' && localStorage.getItem('construction_role') === 'view') &&
                                            <Dropzone
                                              accept={{
                                                "image/*": [".png", ".jpeg", ".jpg", '.heic'],
                                              }}
                                              onDrop={acceptedPlans =>
                                                this.handleAcceptedPlans(acceptedPlans)
                                              }
                                            >
                                              {({ getRootProps, getInputProps }) => (
                                                <div className="dropzone">
                                                  <div
                                                    className="dz-message needsclick"
                                                    {...getRootProps()}
                                                  >
                                                    <input {...getInputProps()} />
                                                    <div className="mb-3">
                                                      <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                                    </div>
                                                    <h4>Para adicionar plantas arraste ou clique aqui.</h4>
                                                  </div>
                                                </div>
                                              )}
                                            </Dropzone>
                                            }
                                            {enterprise?.plans && enterprise?.plans.length > 0 &&
                                              <>
                                                <div style={{margin:'20px 0'}}>
                                                  <p style={{fontSize:'20px', fontWeight:'600'}}>Ordene suas plantas. As 3 primeiras recebem maior destaque.</p>
                                                </div>
                                              </>
                                            }
                                            <div
                                              className="dropzone-previews mt-3"
                                              id="file-previews"
                                            >
                                              {this.state.selectedPlans.map((f, i) => 
                                                  <Col key={i + "-file"} id={f.name} >
                                                    <Card
                                                      className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                    >
                                                      <div className="p-2">
                                                          <Row className="align-items-center">
                                                            <Col className="col-auto">
                                                              <img
                                                                data-dz-thumbnail=""
                                                                height="120"
                                                                className="avatar-sm rounded bg-light image-send"
                                                                alt={f.name}
                                                                src={f.preview}
                                                              />
                                                            </Col>
                                                            <Col>
                                                              <Link
                                                                to="#"
                                                                className="text-muted fw-bold"
                                                              >
                                                                {f.name}
                                                              </Link>
                                                              <p className="mb-0">
                                                                <strong>{f.formattedSize}</strong>
                                                                {this.state.errors[i] ? (
                                                                    <div style={{ color: 'red' }}>{this.state.errors[i].message}</div>
                                                                ) : (
                                                                    <Progress value={this.state.progressBars[i]}/>
                                                                )}
                                                              </p>
                                                            </Col>
                                                          </Row>
                                                      </div>
                                                    </Card>
                                                    </Col>
                                                )}
                                            </div>
                                          </Form>
                                          <EnterprisePlansOrder
                                            onFormDataChange={this.handleFormDataPlans}
                                            imoveis={enterprise?.plans}
                                            deleteImage={this.deleteImage}
                                            count={this.state.count}
                                          />
                                        </Container>
                                            <Container>
                                              <Row style={{margin:'20px 0', justifyContent:'space-between'}}>
                                                <Col className="col-auto">
                                                    <Button className="btn-dark" onClick={() => {
                                                        setTimeout(() => {
                                                            window.open('./../perfil-empreendimento?id=' + id, '_parent')
                                                        }, 100);
                                                    }}>Voltar</Button>
                                                </Col>
                                                <Col className="col-auto">
                                                    <Button onClick={this.updatePositions}>Salvar ordenação</Button>
                                                </Col>
                                              </Row>
                                            </Container>
                                        </AvForm>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <div id="load" className="load">
                    <Spinner className="me-2" color="success" />
                </div>

                              {/* Sucesso na atualização */}

              {this.state.deleteSuccess ? (
              <SweetAlert
                title="Imagem excluída"
                timeout={1500}
                showCloseButton={false}
                showConfirm={false}
                success
                onConfirm={() => this.setState({deleteSuccess: !this.state.deleteSuccess})}
              ></SweetAlert>
              ) : null}

            {/* Falha na atualização */}

            {this.state.deleteError ? (
              <SweetAlert
                title="A exclusão falhou, tente novamente."
                timeout={1500}
                showCloseButton={false}
                showConfirm={false}
                error
                onConfirm={() => this.setState({deleteError: !this.state.deleteError})}
              ></SweetAlert>
              ) : null}

            {/* Falha envio de imagem */}

            {this.state.sendImageError ? (
              <SweetAlert
                title="Algumas imagens não puderam ser enviadas."
                timeout={1500}
                showCloseButton={false}
                showConfirm={false}
                error
                onConfirm={() => {
                  document.getElementById('load').classList.remove('active')
                  this.setState({sendImageError: !this.state.sendImageError})
                }}
              ></SweetAlert>
              ) : null}

              {this.state.error_auth ? (
                  <SweetAlert
                      error
                      title='Ops'
                      onConfirm={() => this.setState({ error_auth: false })}
                  >
                      Você não tem permissão para esta ação.
                  </SweetAlert>
              ) : null}
            </div>
        )
    }

}

export default EnterprisePlans;