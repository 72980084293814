import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import Fire from './../../assets/images/Icons/fogooutlet.png';
import {useNavigate} from 'react-router-dom';

import './../../assets/scss/custom/components/_imovelCard.scss';

import Premium from './../../assets/images/premium.png';
import { Button, Card, Col, Container, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import Slidewithfade from '../../pages/Ui/CarouselTypes/slidewithfade';
// import Verificado from './../../Assets/Icons/verificado.png';

import ImovelCardCarousel from './Carousel';
import Axios from 'axios';

function UnitCard({imovel, premium, favoritos}) {
  const [favoritado, setFavoritado] = useState(favoritos.includes(imovel.id));
  const [modalCenter, setModalCenter] = useState(false)
  const [userPendent, setUserPendent] = useState(localStorage.getItem('status'))

  const handleFavoritarClick = () => {
    setFavoritado(!favoritado); // alterna entre favoritado e não favoritado

    const endpoint = favoritado
      ? "/api/auth/favorite-delete"
      : "/api/auth/favorite";

    Axios.post(process.env.REACT_APP_API + endpoint, {
      user_id: parseInt(localStorage.getItem("id")),
      type: localStorage.getItem("access"),
      property_id: imovel.id,
    }, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
    })
  };

  const date = new Date()
  date.setDate(date.getDate() - 7)

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function(txt){
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  console.log(imovel.construction.name)
  
  return (
    <>
      {(userPendent !== '3' && userPendent !== '2') ?
      <Card className="ImovelCard" style={{boxShadow:'0 0 8px rgba(0,0,0,0.3)'}}>
        <Container>
        <Link to={"../../unidade/" + imovel.slug} target="_parent">
          <Row className="slider-row">
              <ImovelCardCarousel  photos={imovel?.photos?.sort((c1, c2) => (c1?.order > c2?.order) ? 1 : -1).slice(0,4)}/>
              <div className="unity-tag-container">
                <div className="const-unit-cover"
                  style={{backgroundImage:'url(' + imovel?.construction?.profile_photo + ')'}}
                ></div><span className="tag-unity">{imovel?.construction?.name}</span>
              </div>
          </Row>
          <Row>
            <div className='ap'>
              <p>{imovel?.sku}</p>
                <h5 style={{fontSize:'12px'}}>
                  {imovel?.title}
                </h5>
            </div>
          
            <h3 style={{height:'41px', overflow: 'hidden', textOverflow: 'ellipsis'}}>
              {
                imovel?.address_properties?.enterprise && imovel?.address_properties?.enterprise !== "null" ? 
                  toTitleCase(imovel?.address_properties?.enterprise)
                  : <></>
              }
            </h3>
            <p><i className="ri-map-pin-fill" style={{marginRight:'3px'}}></i>{imovel?.address_properties?.city}</p>
          </Row>
          <Row>
            <Col xs={10} style={{padding:'0'}}>
              <Row className="skills">
                {(imovel.dorms_number && imovel.dorms_number !== '0') ?
                  <Col><i className="ri-hotel-bed-fill"></i><p>{imovel?.dorms_number && imovel?.dorms_number !== 0 ? imovel?.dorms_number : imovel?.suites_number}</p></Col>
                  :
                  <></>
                }
                {((!imovel.dorms_number || imovel.dorms_number === '0') && (imovel.suites_number && imovel.suites_number !== '0')) ?
                  <Col><i className="ri-hotel-bed-fill"></i><p>{imovel?.dorms_number && imovel?.dorms_number !== 0 ? imovel?.dorms_number : imovel?.suites_number}</p></Col>
                  :
                  <></>
                }
                {(imovel.parking_spots && imovel.parking_spots !== '0') ?
                  <Col><i className="ri-car-line"></i><p>{imovel?.parking_spots}</p></Col>
                :
                  <></>
                }
                <Col><i className="ri-ruler-line"></i><p>{
                      (imovel?.endorsed_measures && parseInt(imovel?.endorsed_measures) !== 0) ? imovel?.endorsed_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") :
                      (imovel?.terrain_measures && parseInt(imovel?.terrain_measures) !== 0) ? imovel?.terrain_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") :
                      (imovel?.build_measures && parseInt(imovel?.build_measures) !== 0) ? imovel?.build_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") : ''
                      }m²</p></Col>
              </Row>
            </Col>
          </Row>
          </Link>  
          <Row>
            <div className="line"></div>
          </Row>
          <Row>
            <Col xs={10} style={{padding:'0 10px 0 0'}}>
              {imovel?.sell_price && imovel?.sell_price !== 0 ?
                <div className="offer-container">
                  <div className="old-price"><span>R${(imovel?.sale_value / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}</span></div>
                  <div className="offer-price"><small>R$</small><span>{(imovel?.sell_price / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}</span></div>
                </div>
              :
                <div className="offer-container">
                  <div className="offer-price"><small>R$</small><span>{(imovel?.sale_value / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}</span></div>
                </div>
              }
            </Col>
            <Col xs={2} className="like">
              <i
                className={favoritado ? "ri-heart-fill" : "ri-heart-line"}
                style={{ color: "#0f6e43", cursor: 'pointer' }}
                onClick={handleFavoritarClick}
              />
            </Col>
          </Row>
        </Container>   
      </Card>
  :
  //Usuário sem documentos
  <Card className="ImovelCard" style={{boxShadow:'0 0 8px rgba(0,0,0,0.3)'}}>
  <Container>
    <Row className="slider-row">
        <ImovelCardCarousel  photos={imovel?.photos?.sort((c1, c2) => (c1?.order > c2?.order) ? 1 : -1).slice(0,4)}/>
        <div className="unity-tag-container">
          <span className="tag-unity">Unidade</span>
        </div>
    </Row>
    <Row>
      <div className='ap'>
        <p>{imovel?.sku}</p>
          <h5 style={{fontSize:'12px'}}>
            {imovel?.title}
          </h5>
      </div>
    
      <h3 style={{height:'41px', overflow: 'hidden', textOverflow: 'ellipsis'}}>
        {
          imovel?.address_properties?.enterprise && imovel?.address_properties?.enterprise !== "null" ? 
            toTitleCase(imovel?.address_properties?.enterprise)
            : <></>
        }
      </h3>
      <p><i className="ri-map-pin-fill" style={{marginRight:'3px'}}></i>{imovel?.address_properties?.city}</p>
    </Row>
    <Row>
      <Col xs={10} style={{padding:'0'}}>
        <Row className="skills">
          {(imovel.dorms_number && imovel.dorms_number !== '0') ?
            <Col><i className="ri-hotel-bed-fill"></i><p>{imovel?.dorms_number && imovel?.dorms_number !== 0 ? imovel?.dorms_number : imovel?.suites_number}</p></Col>
            :
            <></>
          }
          {((!imovel.dorms_number || imovel.dorms_number === '0') && (imovel.suites_number && imovel.suites_number !== '0')) ?
            <Col><i className="ri-hotel-bed-fill"></i><p>{imovel?.dorms_number && imovel?.dorms_number !== 0 ? imovel?.dorms_number : imovel?.suites_number}</p></Col>
            :
            <></>
          }
          {(imovel.parking_spots && imovel.parking_spots !== '0') ?
            <Col><i className="ri-car-line"></i><p>{imovel?.parking_spots}</p></Col>
          :
            <></>
          }
          <Col><i className="ri-ruler-line"></i><p>{
                (imovel?.endorsed_measures && parseInt(imovel?.endorsed_measures) !== 0) ? imovel?.endorsed_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") :
                (imovel?.terrain_measures && parseInt(imovel?.terrain_measures) !== 0) ? imovel?.terrain_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") :
                (imovel?.build_measures && parseInt(imovel?.build_measures) !== 0) ? imovel?.build_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") : ''
                }m²</p></Col>
        </Row>
      </Col>
    </Row>
    <Row>
      <div className="line"></div>
    </Row>
    <Row>
      <Col xs={10} style={{padding:'0 10px 0 0'}}>
        {imovel?.sell_price && imovel?.sell_price !== 0 ?
          <div className="offer-container">
            <div className="old-price"><span>R${(imovel?.sale_value / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}</span></div>
            <div className="offer-price"><small>R$</small><span>{(imovel?.sell_price / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}</span></div>
          </div>
        :
          <div className="offer-container">
            <div className="offer-price"><small>R$</small><span>{(imovel?.sale_value / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}</span></div>
          </div>
        }
      </Col>
      <Col xs={2} className="like">
        <i
          className={favoritado ? "ri-heart-fill" : "ri-heart-line"}
          style={{ color: "#0f6e43", cursor: 'pointer' }}
          onClick={handleFavoritarClick}
        />
      </Col>
    </Row>
  </Container>   
</Card>
  }
    <Modal
        isOpen={modalCenter}
        toggle={() => setModalCenter(false)}
        centered={true}
    >
        <ModalHeader toggle={() => setModalCenter(false)}
        style={{backgroundColor:"#ed4c4b", minHeight:'120px'}}>
        <h3 style={{color:"#fff"}}>Atenção!</h3>
        </ModalHeader>
        <ModalBody style={{textAlign:'center'}}>
        <p>Valide seu cadastro para visualizar todas as informações do imóvel.</p>
        <br></br>
        <Button onClick={() => {
          window.open('./editar-perfil', '_parent')
        }}>Validar agora</Button>
        </ModalBody>
    </Modal>
  </>
  )
}

export default UnitCard;