import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const GoogleLogin= () => {
  const history = useHistory();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const token = urlParams.get('token');
    const id = urlParams.get('id');
    const type = urlParams.get('type');
    const userName = urlParams.get('user_name');
    const customUrl = urlParams.get('customUrl');

    localStorage.setItem('token', token);
    localStorage.setItem('id', id);
    localStorage.setItem('access', type);
    localStorage.setItem('user_name', userName);

    if(customUrl){
      history.push(customUrl);
    }else{
      history.push('/dashboard');
    }

  }, [history]);

  return (
    <div>
      <h3>Processando login...</h3>
    </div>
  );
};