import { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button, Input } from 'reactstrap';

import './../../assets/scss/custom/components/_mktPopUp.scss';
import Axios from 'axios';

import ConstrutorBg from './../../assets/images/popups/construtor_bg.png';
import ConstrutorPc from './../../assets/images/popups/construtor_pc.png';
import ConstrutorTitle from './../../assets/images/popups/construtor_title.png';

import tinder from './../../assets/images/popups/tinder.png';
import tinderBg from './../../assets/images/popups/fundo.png';
import tinderLogo from './../../assets/images/popups/logotinder.png';

import ConstructionBg from './../../assets/images/fundo_construtoras_popup.png';
import ConstructionLogo from './../../assets/images/logo_construtoras.png';

import Countdown from 'react-countdown-simple';

const targetDate = new Date('2023-11-21T21:00:00').toISOString();

const renderer = ({ days, hours, minutes, seconds }) =>(
  <div className="serie_countdown">
    <div className="serie_countdown_item">
      <div>{days}</div>
      <p>Dias</p>
    </div>
    <span>:</span>
    <div className="serie_countdown_item">
      <div>{hours}</div>
      <p>Horas</p>
    </div>
    <span>:</span>
    <div className="serie_countdown_item">
      <div>{minutes}</div>
      <p>Minutos</p>
    </div>
  </div>)

class PopUps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_center: true,
      modalLevel:0,
      name:'',
      city:'',
      show:false,
    };
    this.tog_center = this.tog_center.bind(this);
  }

  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  tog_center() {
    this.setState(prevState => ({
      modal_center: !prevState.modal_center
    }));
    this.removeBodyCss();
  }

  componentDidMount(){

    this.setState({modal_center: true})


    if(localStorage.getItem('access') === 'C' || localStorage.getItem('access') === 'I'){
      const newData = new FormData()
      if(localStorage.getItem('access') === 'C'){
        newData.append('broker_id', localStorage.getItem('id'))
      }
      if(localStorage.getItem('access') === 'I'){
        newData.append('real_estate_id', localStorage.getItem('id'))
      }
  
      Axios.post(process.env.REACT_APP_API + '/api/auth/order-new-construction-exist', newData,
      {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
      .then(response => this.setState({show: false}))
      .catch(response => this.setState({show: true}))
    }


      Axios.get(process.env.REACT_APP_API + '/api/auth/get-popup-level/' + localStorage.getItem('id') + '/' + localStorage.getItem('access'),
      {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
      .then(resp =>{
        // console.log(resp.data)
        this.setState({modalLevel: resp.data.popup_level})
        if(resp.data.popup_level === null || resp.data.popup_level < 14){
            this.setState({modal_center: true})

            Axios.post(process.env.REACT_APP_API + '/api/auth/update-popup-level', {
              type_user: localStorage.getItem('access'),
              id: localStorage.getItem('id'),
              popup_level: resp.data.popup_level ? (resp.data.popup_level + 1) : 1
            }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
          }

        // if(resp.data.popup_level === 1){
        //   this.setState({modalLevel: 2})
        //   this.setState({modal_center: true})

        //   Axios.post(process.env.REACT_APP_API + '/api/auth/update-popup-level', {
        //     type_user: localStorage.getItem('access'),
        //     id: localStorage.getItem('id'),
        //     popup_level: 2
        //   }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        // }
        // if(resp.data.popup_level === 2){
        //   this.setState({modalLevel: 3})
        //   this.setState({modal_center: true})

        //   Axios.post(process.env.REACT_APP_API + '/api/auth/update-popup-level', {
        //     type_user: localStorage.getItem('access'),
        //     id: localStorage.getItem('id'),
        //     popup_level: 3
        //   }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        // }
        // if(resp.data.popup_level === 3){
        //   this.setState({modalLevel: 4})
        //   this.setState({modal_center: true})

        //   Axios.post(process.env.REACT_APP_API + '/api/auth/update-popup-level', {
        //     type_user: localStorage.getItem('access'),
        //     id: localStorage.getItem('id'),
        //     popup_level: 4
        //   }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        // }
      })
  }

  handleSendConstruction = () => {
    const data = new FormData()
    data.append('name', this.state.name)
    data.append('city', this.state.city)
    if(localStorage.getItem('access') === 'C'){
      data.append('broker_id', localStorage.getItem('id'))
    }
    if(localStorage.getItem('access') === 'I'){
      data.append('real_estate_id', localStorage.getItem('id'))
    }
    Axios.post(process.env.REACT_APP_API + '/api/auth/order-new-construction', data,
      {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
      .then(response => {
        this.setState({show: false})
      }).catch(response => {
        this.setState({show: false})
      })
  }

  render(){
    const {modalLevel} = this.state;

    return (
      <>
      {this.state.show &&
        <Modal
          isOpen={this.state.modal_center}
          toggle={this.tog_center}
          centered={true}
          size="lg"
          className="modal-tinder"
        >
          <ModalBody className='subtitle' style={{backgroundImage:`url('${ConstructionBg}')`}}>
          <div className='content-construtoras'>
              <i className="ri-close-fill" onClick={this.tog_center}></i>
              <div className="content-img">
                <img src={ConstructionLogo} alt='Site Banner'></img>
              </div>
              <div className="content-text">
                <h2>Fast Sale, agora para <b>construtoras.</b></h2>
                <p>Qual construtora ou incorporadora você quer vender na Fast?</p>
                <Input
                  type="text"
                  value={this.state.name}
                  onChange={(e) => this.setState({name: e.target.value})}
                  placeholder="Nome"
                ></Input>
                <Input
                  type="text"
                  value={this.state.city}
                  onChange={(e) => this.setState({city: e.target.value})}
                  placeholder="Cidade"
                ></Input>
                {this.state.name !== '' && this.state.city !== '' ?
                  <button onClick={this.handleSendConstruction}>Enviar</button>
                :
                  <button disabled>Preencha o nome e cidade</button>
                }
              </div>
            </div>
          </ModalBody>
        </Modal>
      }
      {/* {modalLevel < 14 &&
        <Modal
          isOpen={this.state.modal_center}
          toggle={this.tog_center}
          centered={true}
          size="lg"
          className="modal-tinder"
        >
          <ModalBody className='subtitle' style={{backgroundImage:`url('${tinderBg}')`}}>
          <div className='content-fs'>
              <i className="ri-close-fill" onClick={this.tog_center}></i>
              <div className="content-img">
                <div className="text">
                  <h2>Chegou o</h2>
                  <h2><b>Tinder dos Imóveis</b></h2>
                </div>
                <img src={tinder} alt='Site Banner'></img>
              </div>
              <div className="content-text">
                <h5>Não encontrou o imóvel que procurava na plataforma?</h5>
                <p>Faça seu pedido para milhares de corretores com a segurança da Fast Sale</p>
                <Button className='button-generate' onClick={() => {
                  this.props.ctaAction()
                  this.setState({modal_center: false})
                }}>
                  QUERO PEDIR UM IMÓVEL
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      } */}
      {/* {modalLevel < 4 && (
        <Modal
          isOpen={this.state.modal_center}
          toggle={this.tog_center}
          centered={true}
          size="lg"
          className="modal-site"
        >
          <ModalBody className='subtitle' style={{backgroundImage:`url('${ConstrutorBg}')`}}>
          <div className='content-fs'>
              <i className="ri-close-fill" onClick={this.tog_center}></i>
              <div className="content-text">
                <h3>NOVO</h3>
                <h5>CRIADOR DE</h5>
                <img src={ConstrutorTitle} alt='Site Banner'></img>
                <h4>PARA CORRETORES</h4>
                <h4>E IMOBILIÁRIAS</h4>
                <p>É hora de construir sua <b>presença online</b> e <b>impulsionar seus negócios</b> com nosso novo sistema intuitivo de criação de sites.</p>
              </div>
              <img className='img-banner' src={ConstrutorPc} alt='Site Banner'></img>
            </div>
            <div className='container-buttons'>
              <Button className='button-generate' onClick={() => {
                setTimeout(() => {
                  if(!this.props.verifyPremium){
                    window.open('https://wa.me/554741081865?text=Ol%C3%A1%2C+tenho+interesse+em+criar+meu+SITE+FS%2C+poderia+me+passar+mais+informa%C3%A7%C3%B5es%3F', '_blank')
                  }else{
                    window.open("https://seusite.fastsaleimoveis.com.br/", '_blank')
                  }
                }, 300);
              }}>
                Quero criar meu site!
              </Button>
            </div>
          </ModalBody>
        </Modal>
      )} */}
      </>
    );
  }
}

export default PopUps;