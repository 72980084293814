import Axios from 'axios';
import React, { Component } from 'react';
import { Card, CardBody, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, CardImg, Row, CardTitle, CardText, Button, Badge } from "reactstrap";

//Simple bar
import './../../assets/scss/custom/components/_enterprisesDash.scss'

class EnterpriseTopActions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            enterprises:[],
        }
    }

    componentDidMount(){
            Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/top-enterprises/' + localStorage.getItem('id'),
            {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(response => {
                this.setState({imoveis: response.data})
            })
    }

    render() {
        return (
            <React.Fragment>
                <Col xs={12}>
                    <Card>
                        <CardBody style={{marginTop:'20px'}}>
                        <h4 className="card-title mb-4">Empreendimentos com mais interesse pelos corretores</h4>
                            <div className="enterpriseRankingGrid">
                            {this.state.imoveis?.length > 0 ? (this.state.imoveis?.map((enterprise, index) => (
                            <div key={index} className="enterpriseRankingCard">
                                <div className="rankingCover" style={{
                                    backgroundImage:'url(' + enterprise?.photos[0].url + ')'
                                }}></div>
                                <div className="rankingTitle">
                                    {enterprise?.title}
                                </div>
                                <div className="rankingPosition">{index + 1}º</div>
                            </div>
                            ))):(
                                <p>Você ainda não tem empreendimentos cadastrados.</p>
                            )}
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </React.Fragment>
        );
    }
}

export default EnterpriseTopActions;