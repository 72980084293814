import { AvField, AvForm } from 'availity-reactstrap-validation';
import Axios from 'axios';
import moment from 'moment';
import React, { Component } from 'react';
import ReactSelect from 'react-select';
import { Col, Row, Button, ModalFooter, ModalBody, ModalHeader, Modal, Label, FormGroup, InputGroup } from "reactstrap";
import ContractUse from './ContractUse';
import Flatpickr from "react-flatpickr"
import SweetAlert from 'react-bootstrap-sweetalert';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { CapterCard } from './components/capterCard';


const scheduleHours = [
  {hour: 8, minute: '00'},
  {hour: 8, minute: '30'},
  {hour: 9, minute: '00'},
  {hour: 9, minute: '30'},
  {hour: 10, minute: '00'},
  {hour: 10, minute: '30'},
  {hour: 11, minute: '00'},
  {hour: 11, minute: '30'},
  {hour: 12, minute: '00'},
  {hour: 12, minute: '30'},
  {hour: 13, minute: '00'},
  {hour: 13, minute: '30'},
  {hour: 14, minute: '00'},
  {hour: 14, minute: '30'},
  {hour: 15, minute: '00'},
  {hour: 15, minute: '30'},
  {hour: 16, minute: '00'},
  {hour: 16, minute: '30'},
  {hour: 17, minute: '00'},
  {hour: 17, minute: '30'},
  {hour: 18, minute: '00'},
  {hour: 18, minute: '30'},
  {hour: 19, minute: '00'},
  {hour: 19, minute: '30'},
]

class SingleAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      acceptContract: false,
      modal_schedule: false,
      newEventType:null,
      newEventClientName:null,
      newEventHour:null,
      newEventDateFormated:null,
      schedule_success:false,
      schedule_error:false,
      diaSelecionado:null,
      mesSelecionada:null,
      hoursOptions:null,
      openEnterpriseErrorModal:false,
    };
    this.tog_schedule = this.tog_schedule.bind(this)
    this.handleContractClosed = this.handleContractClosed.bind(this);
  }

  tog_schedule(){
    this.setState(prevState => ({
      modal_schedule: !prevState.modal_schedule
    }));
    this.removeBodyCss();
  }

  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  handleContractClosed() {
    this.setState({ acceptContract: false });
  }

  handleContractAccepted() {
    if(this.state.imovel.broker_id != localStorage.getItem('id')){
      this.setState({acceptContract: false})
      this.setState(prevState => ({
        modal_schedule: !prevState.modal_schedule
      }));
      this.removeBodyCss();

      Axios.post(process.env.REACT_APP_API + '/api/auth/verify-contract', {
        type:localStorage.getItem('access'),
        user_id:localStorage.getItem('id'),
        property_id:this.props.imovel.id
        }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
      .then(resp => {
        this.props.handleVerifyContract(true);
      })
      .catch(resp => {
        this.props.handleVerifyContract(false);
      })
    }else{
      this.props.handleVerifyContract(true)
    }
  }

  componentDidUpdate() {
    document.querySelector('.visitTypeBtnContainer')?.addEventListener('click', function(e) {
        for(var i =0; document.querySelectorAll('.visitTypeBtn').length > i; i++){
          document.querySelectorAll('.visitTypeBtn')[i].classList.remove('active')
        }
          e.target.classList.add('active')
      }, false);

      document.querySelector('.hour-container')?.addEventListener('click', function(e) {
        for(var i =0; document.querySelectorAll('.hour').length > i; i++){
          document.querySelectorAll('.hour')[i].classList.remove('active')
        }
          e.target.classList.add('active')
      }, false);
}

generateHoursOption() {
  let hoursOptions = null; // Inicialize como nulo

  if (parseInt(this.state.mesSelecionado) >= parseInt(moment(new Date()).format('MM'))) {
    if (parseInt(this.state.dataSelecionada) === parseInt(moment(new Date()).format('DD'))) {
      if (scheduleHours.filter(hour => parseInt(hour.hour) > (parseInt(moment(new Date()).format('HH')) + 3)).length > 0) {
        hoursOptions = scheduleHours
          .filter(hour => parseInt(hour.hour) > (parseInt(moment(new Date()).format('HH')) + 3))
          .map((hour, index) => (
            <div
              key={index}
              className="hour"
              onClick={() => this.setState({ newEventHour: hour.hour + ":" + hour.minute })}
            >
              {hour.hour}:{hour.minute}
            </div>
          ));
      } else {
        hoursOptions = <p style={{ position: 'absolute' }}>Sem horários disponíveis para esta data.</p>;
      }
    } else if (parseInt(this.state.dataSelecionada) < parseInt(moment(new Date()).format('DD')) && parseInt(this.state.mesSelecionado) > parseInt(moment(new Date()).format('MM'))) {
        hoursOptions = scheduleHours.map((hour, index) => (
          <div
            key={index}
            className="hour"
            onClick={() => this.setState({ newEventHour: hour.hour + ":" + hour.minute })}
          >
            {hour.hour}:{hour.minute}
          </div>
        ));
    } else if (parseInt(this.state.dataSelecionada) < parseInt(moment(new Date()).format('DD'))) {
      hoursOptions = <p style={{ position: 'absolute' }}>Sem horários disponíveis para esta data.</p>;
    } else {
      hoursOptions = scheduleHours.map((hour, index) => (
        <div
          key={index}
          className="hour"
          onClick={() => this.setState({ newEventHour: hour.hour + ":" + hour.minute })}
        >
          {hour.hour}:{hour.minute}
        </div>
      ));
    }
  } else {
    hoursOptions = <p style={{ position: 'absolute' }}>Sem horários disponíveis para esta data.</p>;
  }

  // Defina hoursOptions no estado
  this.setState({ hoursOptions });
}

  render(){
    const { premium } = this.props;
    const locale = {
      weekdays: {
        shorthand: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
        longhand: [
          'Domingo',
          'Segunda-feira',
          'Terça-feira',
          'Quarta-feira',
          'Quinta-feira',
          'Sexta-feira',
          'Sábado'
        ]
      },
      months: {
        shorthand: [
          'Jan',
          'Fev',
          'Mar',
          'Abr',
          'Mai',
          'Jun',
          'Jul',
          'Ago',
          'Set',
          'Out',
          'Nov',
          'Dez'
        ],
        longhand: [
          'Janeiro',
          'Fevereiro',
          'Março',
          'Abril',
          'Maio',
          'Junho',
          'Julho',
          'Agosto',
          'Setembro',
          'Outubro',
          'Novembro',
          'Dezembro'
        ]
      },
      firstDayOfWeek: 0,
      rangeSeparator: ' até ',
      weekAbbreviation: 'Sem',
      scrollTitle: 'Rolagem para aumentar',
      toggleTitle: 'Clique para alternar',
      amPM: ['AM', 'PM'],
      yearAriaLabel: 'Ano',
      time_24hr: true
    };
    return(
      <div id="actionContainer" style={{minWidth:'300px'}}>
      <ContractUse
        acceptContract={this.state.acceptContract}
        onContractClosed={this.handleContractClosed}
        onContractAccept={this.handleContractAccepted.bind(this)}
        premium={premium}
        imovel={this.props.imovel}
      />
      <Row>
        <Col>
        {this.props.imovel.sell_price ?
        <>
          <div className="offer"><h3>{(parseFloat(this.props.imovel.sale_value) / 100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</h3></div>
          <div className="price"><h3>{(parseFloat(this.props.imovel.sell_price) / 100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</h3></div>
        </>
        :
        <div className="price"><h3>{(parseFloat(this.props.imovel.sale_value) / 100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</h3></div>
        }
        </Col>
      </Row>
      {this.props.imovel?.sell_contract_type === 'FS Parc' || this.props.imovel?.sell_contract_type === 'FS Parc (sc)' ?
        <Row style={{justifyContent:'space-between'}}>
          {/* <span className='more'>Até</span> */}
          <Col xs={6} className="percentage" style={{backgroundColor:'#b5b5b5'}}><span style={{color:'#333', position:'static', fontSize:'13px', width:'100%'}}>50% da comissão</span></Col>
          <Col xs={1} className="percentage-info" style={{padding:'0', cursor:'pointer'}}><i className="ri-information-line" style={{fontSize:'18px'}}></i></Col>
          <Col xs={4} className="sellContract" style={{backgroundColor:'#f1ce41', alignItems:'center', display:'flex', justifyContent:'center'}}><span style={{color:'#333', fontSize:'13px'}}>Parceria</span></Col>
        </Row>
      :
        (premium ?
          <Row style={{justifyContent:'space-between'}}>
            <span className='more'>Até</span>
            <Col xs={7} className="percentage" style={{backgroundColor:'#3E5E3F'}}><div>{parseFloat(this.props.imovel.commission_percentage_on_sale).toLocaleString('pt-br', {minimumFractionDigits: 1}).toLocaleString('pt-br', {maximumFractionDigits: 1})}%</div><span>{((this.props.imovel.sell_price ? this.props.imovel.sell_price / 100 : this.props.imovel.sale_value / 100) * (parseFloat(this.props.imovel.commission_percentage_on_sale) / 100)).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span></Col>
            <Col xs={5} className="sellContract" style={{backgroundColor:'#3E5E3F'}}>Contrato FS</Col>
          </Row>
          :
          <Row style={{justifyContent:'space-between'}}>
            <Col xs={6} className="percentage" style={{backgroundColor:'#b5b5b5'}}><span style={{color:'#333', position:'static', fontSize:'13px', width:'100%'}}>50% da comissão</span></Col>
            <Col xs={1} className="percentage-info" style={{padding:'0', cursor:'pointer'}}><i className="ri-information-line" style={{fontSize:'18px'}}></i></Col>
            <Col xs={4} className="sellContract" style={{backgroundColor:'#f1ce41', alignItems:'center', display:'flex', justifyContent:'center'}}><span style={{color:'#333', fontSize:'13px'}}>Parceria</span></Col>
            <Col xs={12} style={{padding:0}}>
              <div className="premium-property-container">
                  <h3>Venda esse imóvel e receba</h3>
                  <div>
                    <span>até {parseFloat(this.props.imovel.commission_percentage_on_sale).toLocaleString('pt-br', {minimumFractionDigits: 1}).toLocaleString('pt-br', {maximumFractionDigits: 1})}%</span>
                    <small>de comissão</small>
                  </div>
                  <button onClick={() => {
                    setTimeout(() => {
                      window.open('https://wa.me/554741081865', '_blank')
                    }, 500);
                  }}>Seja Premium</button>
              </div>
            </Col>
          </Row>
        )
      }
      <Row>
        {(this.props.imovel?.building_tax_value && this.props.imovel?.building_tax_value !== 0 && this.props.imovel?.building_tax_value !== '0' && this.props.imovel?.building_tax_value !== null) &&
          <Col xs={12} className="condominium" style={{padding:'0'}}>
            <small>Condomínio</small>
            <p><span>R$</span>{(this.props.imovel?.building_tax_value / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}<small>/mês</small></p>
          </Col>
        }
        {(this.props.imovel?.city_tax_value && this.props.imovel?.city_tax_value !== 0 && this.props.imovel?.city_tax_value !== '0' && this.props.imovel?.city_tax_value !== null) &&
          <Col xs={12} className="iptu" style={{padding:'0'}}>
            <small>IPTU</small>
            <p><span>R$</span>{(this.props.imovel?.city_tax_value / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}<small>/ano</small></p>
          </Col>
        }
      </Row>
      {this.props.verifyContract &&
        <Row style={{marginTop: '15px'}}>
          {this.props.imovel.broker_id ?
            <CapterCard data={this.props.imovel.broker}/>
            :
            this.props.imovel.real_estate_id ?
            <CapterCard data={this.props.imovel.real_estate}/>
            :
            this.props.imovel.construction_id ?
            <CapterCard data={this.props.imovel.construction}/>
            :
            <></>
          }
        </Row>
      }
      {/* <Row style={{marginTop:'15px'}}>
        <Button className="btn-primary" onClick={() => {
          if(localStorage.getItem('access') === 'T'){
            this.setState({openEnterpriseErrorModal: true})
          }else{
            localStorage.setItem('property_id_proposal', this.props.imovel.id)
            setTimeout(() => {
              window.open('/criar-proposta', '_parent')
            }, 300);
          }
        }}>Fazer proposta</Button>
      </Row> */}
      {/* <Row style={{marginTop:'15px'}}>
        <Button className="agendarVisita waves-effect waves-light"
        onClick={() => {
          if(localStorage.getItem('access') === 'T'){
            this.setState({openEnterpriseErrorModal: true})
          }else{
            if(this.props.imovel.broker_id != localStorage.getItem('id')){
              Axios.post(process.env.REACT_APP_API + '/api/auth/verify-contract', {
                type:localStorage.getItem('access'),
                user_id:localStorage.getItem('id'),
                property_id:this.props.imovel?.id
                }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
              .then(response => {
                this.tog_schedule()
              })
              .catch(response => {
                this.setState({acceptContract: true})
              })
            }else{
              this.tog_schedule()
            }
          }
          }}>Agendar visita</Button>
      </Row> */}

        <Modal
            centered={true}
            isOpen={this.state.modal_schedule}
            toggle={this.tog_schedule}
          >
            <ModalHeader toggle={() => this.setState({ modal_schedule: false })}>
              <div className="popupText">
                <p>Agendar uma visita para o imóvel: <span style={{cursor:'initial', textDecoration:'none'}}>{this.props.imovel?.title} <b>({this.props.imovel?.sku})</b></span></p>
              </div>
            </ModalHeader>
            <ModalBody toggle={() => this.setState({ modal_schedule: false })}>
              <AvForm>
                <Row form>
                    <Col xs={12}>
                        <FormGroup className="mb-4">
                            <Label>Data</Label>
                            <InputGroup>
                            <Flatpickr
                                className="form-control d-block"
                                placeholder="Selecione um dia"
                                options={{
                                altInput: true,
                                altFormat: "j, F, Y",
                                dateFormat: "Y-m-d",
                                }}
                                locale= {locale}
                                onChange={(e) => {
                                    this.setState({ newEventDateFormated: moment(e.toString()).format('YYYY-MM-DD')})
                                    this.setState({ dataSelecionada: moment(e.toString()).format('DD')})
                                    this.setState({ mesSelecionado: moment(e.toString()).format('MM')})
                                    setTimeout(() => {
                                      this.generateHoursOption()
                                    }, 300);
                                }}
                            />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    <Col xs={12} className="hourContainer">
                      <Label>Horário:</Label>
                      <div className="hour-container" style={{ minHeight: '40px' }}>
                          {this.state.hoursOptions}
                      </div>
                    </Col>
                    <Col xs={12}>
                        <Label>Nome do cliente:</Label>
                        <AvField
                            name="clienteName"
                            type="text"
                            errorMessage="Campo obrigatório"
                            validate={{
                                required: { value: true },
                            }}
                            value={this.state.newEventClientName}
                            onChange={(e) => this.setState({newEventClientName: e.target.value})}
                        />
                    </Col>
                </Row>
                <Row style={{marginTop:'20px'}}>
                    <Col xs={12}>
                        <Label>Tipo da visita:</Label>
                    </Col>
                    <Col xs={12}>
                        <Row className="visitTypeBtnContainer">
                            <Col xs={6}>
                                <div className="visitTypeBtn" onClick={() => {
                                    this.setState({newEventType : 1})
                                }}>
                                    Visita no local
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="visitTypeBtn" onClick={() => {
                                    this.setState({newEventType : 0})
                                }}>
                                    Visita videochamada
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </AvForm>
            </ModalBody>
            <ModalFooter>
              <div className="popupButtons" style={{display: 'flex', justifyContent: 'space-between'}}>
                <Button type="button" color="light"  onClick={() => this.setState({ modal_schedule: false })}>Cancelar</Button>
                {(this.state.newEventType && this.state.newEventDateFormated && this.state.newEventClientName && this.state.newEventHour && localStorage.getItem('access') && this.props.imovel.id && localStorage.getItem('id')) ?
                <Button onClick={() => {
                  document.getElementById('load').classList.add('active')
                  const data = {
                    status: 0,
                    event_type: this.state.newEventType,
                    schedule_date:  this.state.newEventDateFormated,
                    client_name: this.state.newEventClientName,
                    schedule_time: this.state.newEventHour,
                    type: localStorage.getItem('access'),
                    property_id: this.props.imovel.id,
                    user_id: parseInt(localStorage.getItem('id'))
                  }
                  Axios.post(process.env.REACT_APP_API + '/api/auth/scheduling', data,
                  {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                  .then(res => {
                    this.setState({ modal_schedule: false })
                    document.getElementById('load').classList.remove('active')
                    this.setState({ schedule_success: true })
                  })
                  .catch(res => {
                    this.setState({ modal_schedule: false })
                    document.getElementById('load').classList.remove('active')
                    this.setState({ schedule_error: true })
                  })
                }}>Agendar</Button>
                :
                <Button disabled>Agendar (selecione a data e horário)</Button>
                }
              </div>
            </ModalFooter>
          </Modal>

          {/* Sucesso no agendamento */}

          {this.state.schedule_success ? (
            <SweetAlert
              title="Visita agendada com sucesso!"
              timeout={2000}
              showCloseButton={false}
              showConfirm={false}
              success
              onConfirm={() => this.setState({ schedule_success: false })}
            ></SweetAlert>
            ) : null}

          {/* Falha no agendamento */}

          {this.state.schedule_error ? (
            <SweetAlert
              title="O agendamento falhou, tente novamente."
              timeout={2000}
              showCloseButton={false}
              showConfirm={false}
              error
              onConfirm={() => this.setState({ schedule_error: false })}
            ></SweetAlert>
            ) : null}

          {this.state.openEnterpriseErrorModal ? (
            <SweetAlert
              title="Esta função não está disponível para construtoras."
              onConfirm={() => this.setState({ openEnterpriseErrorModal: false })}
            ></SweetAlert>
          ) : null}

    </div>
    )
  }
}

  export default SingleAction;