import { Card, CardBody, Col, Container, Input, Label, Row } from 'reactstrap';
import './styles.scss';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import logoDark from "../../../assets/images/logo-dark.png";
import { useEffect, useState } from 'react';
import Axios from 'axios';
import Whats from '../../../components/Whats/Whats';

export const CheckoutCart = () => {
  const history = useHistory()
  const location = useLocation()
  const [productsList, setProductsList] = useState([])
  const [userTypes, setUserTypes] = useState([])
  const [selectedUserType, setSelectedUserType] = useState('');
  const [filteredPlans, setFilteredPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [recurrenceOptions, setRecurrenceOptions] = useState([]);
  const [selectedRecurrence, setSelectedRecurrence] = useState('');
  const [planPrice, setPlanPrice] = useState(0);

  useEffect(() => {
    Axios.get(process.env.REACT_APP_API + '/api/public/list-products')
    .then(response => {
      setProductsList(response.data)

      const uniqueUserTypes = Array.from(
        new Set(response.data.map(product => product.user_type))
      ).map(type => {
        switch(type) {
          case 'C': return { value: 'C', label: 'Corretor' }
          case 'I': return { value: 'I', label: 'Imobiliária' }
          case 'T': return { value: 'T', label: 'Construtora' }
          default: return { value: '', label: '' }
        }
      });

      setUserTypes(uniqueUserTypes)
    })
  }, [])

  const params = new URLSearchParams(location.search);
  const product = params.get('product');
  const recurrence = params.get('recurrence');
  const coupon = params.get('coupon');

  useEffect(() => {

    if (product && productsList.length > 0) {
      setSelectedUserType(productsList?.find(products => products.id == product).user_type)
      setSelectedPlan(product)
    }

    if (recurrence) {
      setSelectedRecurrence(recurrence);
    }
  }, [location.search, productsList]);


  useEffect(() => {

    if (selectedUserType) {
      const plans = productsList.filter(product => product.user_type == selectedUserType);
      setFilteredPlans(plans);
      setRecurrenceOptions([]);
      setSelectedRecurrence('');
      setPlanPrice(0);
      if(product){
        setSelectedPlan(product);
      }else{
        setSelectedPlan(null);
      }
    } else {
      setFilteredPlans([]);
    }
  }, [selectedUserType, productsList]);

  useEffect(() => {
    if (selectedPlan) {
      const plan = productsList?.find(product => product.id == selectedPlan);
      const options = [];
      if (plan.recurrence_month) options.push({ value: 'month', label: 'Mensal', multiplier: 1 });
      if (plan.recurrence_third) options.push({ value: 'third', label: 'Trimestral', multiplier: 3 });
      if (plan.recurrence_six) options.push({ value: 'six', label: 'Semestral', multiplier: 6 });
      if (plan.recurrence_year) options.push({ value: 'year', label: 'Anual', multiplier: 12 });
      setRecurrenceOptions(options);

      setPlanPrice(plan.price);
    } else {
      setRecurrenceOptions([]);
      setPlanPrice(0);
    }

    if (recurrence) {
      setSelectedRecurrence(recurrence);
    }else{
      setSelectedRecurrence('');
    }
  }, [selectedPlan, productsList]);

  useEffect(() => {
    if (selectedRecurrence) {
      const option = recurrenceOptions.find(option => option.value === selectedRecurrence);
      if (option) {
        const plan = productsList.find(product => product.id == selectedPlan);
        setPlanPrice(plan.price * option.multiplier);
      }
    } else {
      setPlanPrice(0);
    }
  }, [selectedRecurrence, recurrenceOptions, selectedPlan, productsList]);

  return(
    productsList && productsList.length > 0 &&
    <div className="checkout-cart-section">
      <Container>
        <Row className="top-bar">
          <Col className="col-auto">
            <img src={logoDark}/>
          </Col>
          <Col className="col-auto">
            <span>Checkout Seguro</span>
            <i className="ri-lock-fill"></i>
          </Col>
        </Row>
      </Container>
      <Card>
        <CardBody>
          <Container>
            <Row>
              <Col xs={12}>
                <h3>Revise as opções do seu plano</h3>
              </Col>
              <Col xs={12}>
                <Row className="checkout-cart-item-label">
                  <Col xs={1}></Col>
                  <Col xs={3}>Tipo</Col>
                  <Col xs={3}>Plano</Col>
                  <Col xs={3}>Recorrência</Col>
                  <Col xs={2}>Valor</Col>
                </Row>
                <Row className="checkout-cart-item">
                  <Col md={1}>
                    <img src=""/>
                  </Col>
                  <Col md={3}>
                    <Label>Tipo</Label>
                    <Input type="select" value={selectedUserType} onChange={(e) => setSelectedUserType(e.target.value)}>
                      <option value="">Selecione</option>
                      {userTypes.map(type => (
                        <option key={type.value} value={type.value}>{type.label}</option>
                      ))}
                    </Input>
                  </Col>
                  <Col md={3}>
                    <Label>Plano</Label>
                    <Input type="select" value={selectedPlan} onChange={(e) => setSelectedPlan(e.target.value)}>
                      <option value="">Selecione</option>
                      {filteredPlans.filter(plans => plans.slug !== 'free').map(plan => (
                        <option key={plan.id} value={plan.id}>{plan.name}</option>
                      ))}
                    </Input>
                  </Col>
                  <Col md={3}>
                    <Label>Recorrência</Label>
                    <Input type="select" value={selectedRecurrence} onChange={(e) => setSelectedRecurrence(e.target.value)}>
                      <option value="">Selecione</option>
                      {recurrenceOptions.map(option => (
                        <option key={option.value} value={option.value}>{option.label}</option>
                      ))}
                    </Input>
                  </Col>
                  <Col md={2} className="value">
                    {(productsList?.find(products => products.id == selectedPlan)?.product_plans[0].card_year_discount !== 0 && selectedRecurrence === 'year') ?
                      <>
                        <h6 style={{textDecoration:'line-through'}}>{planPrice.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</h6>
                        <h5>{(planPrice / (100 / (productsList?.find(products => products.id == selectedPlan)?.product_plans[0].card_year_discount / 10))).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</h5>
                      </>
                    :
                      <h5>{planPrice.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</h5>
                    }
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </CardBody>
      </Card>
      <Container>
        <Row className="checkout-cart-values-container">
          <Col className="col-auto">
            <div className="checkout-cart-values">
              {selectedRecurrence === 'year' ?
              <>
                <div className="economy">
                  <span>Economia plano anual</span>
                  <p>{(planPrice - (planPrice / (100 / (productsList.find(products => products.id == selectedPlan)?.product_plans[0].card_year_discount / 10)))).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                </div>
                <p className="atual-price">{(planPrice / (100 / (productsList.find(products => products.id == selectedPlan)?.product_plans[0].card_year_discount / 10))).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                <span>até 12x de {((planPrice / (100 / (productsList.find(products => products.id == selectedPlan)?.product_plans[0].card_year_discount / 10)) - 0.6) / 12).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}<br />
                {(productsList.find(products => products.id == selectedPlan)?.product_plans[0].pix_year_discount !== 0 && selectedRecurrence === 'year') &&
                  <span>ou {(planPrice - (planPrice / (100 / (productsList.find(products => products.id == selectedPlan)?.product_plans[0].pix_year_discount / 10)))).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} no pix</span>
                }</span>
              </>
              :
              <p className="atual-price">{(planPrice).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
            }
              <button onClick={() => {
                history.push(`/novo-checkout?product=${selectedPlan}&recurrence=${selectedRecurrence}${coupon ? '&coupon=' + coupon : ''}`)
              }}>Ir para pagamento</button>
            </div>
          </Col>
        </Row>
      </Container>
      <Whats/>
    </div>
  )
}