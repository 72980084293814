import {Component} from 'react'
import FiltersSection1 from './Section1'
import FiltersSection2 from './Section2'
import FiltersSection3 from './Section3'
import FiltersSection7 from './Section7'
import FiltersSection4 from './Section4'
import FiltersSection5 from './Section5'
import FiltersSection6 from './Section6'

import './../../assets/scss/custom/components/_filters.scss'
import { Button, Card, CardBody, CardHeader, Col, Collapse, Input, Row } from 'reactstrap'

class Filters extends Component {

  constructor(props) {
    super(props)
    this.state = {
      activeTab: "1",
      isCategoryOpen0 : true,
      isCategoryOpen1 : false,
      isCategoryOpen2 : false,
      isCategoryOpen3 : false,
      isCategoryOpen4 : false,
      isCategoryOpen5 : false,
      isCategoryOpen6 : false,
      moreFilters:false,
      moreFiltersIcon:'ri-add-line',
    }
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

componentDidMount(){
  if(window.screen.width < 768){
    this.setState({isCategoryOpen0 : false})
    document.getElementById('accordion').style.display= 'none'
    document.getElementById('moreFilters').style.display= 'flex'
  }else{
    document.getElementById('accordion').style.display= 'block'
    document.getElementById('moreFilters').style.display= 'none'
  }
}

  render(){
    return (
        <div className="Filters">
          <Card>
            <CardHeader className="bg-transparent border-bottom">
              <div style={{display: 'flex',justifyContent: 'space-between' ,alignItems: 'center'}}>
                {this.props.onToogle ? (
                    <h5 className="mb-0">Filtros</h5>
                  ) : (
                    <h5 className="mb-0 none-text-filter">Filtros</h5>
                  )
                }
                {
                  this.props.onToogle ? (
                      <i className="ri-close-line" onClick={this.props.toogle} style={{cursor: "pointer", fontSize: "1.3rem"}}></i>
                    ) : (
                      <i className="ri-filter-2-fill" onClick={this.props.toogle} style={{cursor: "pointer", fontSize: "1.3rem"}}></i>
                  )
                }
              </div>
            </CardHeader>
            {this.props.onToogle &&
                    <CardBody>
                      <div>
                        <Col className="searchField">
                          <Input 
                            placeholder={this.props.premium ? 'Busque por Empreend., tag, local ou código' : 'Busque por título, tag, local ou código'}
                            id="inputText" 
                            style={{border:'none', backgroundColor: '#f4f4f4'}}
                            onChange={(e) => this.setState({search : e.target.value})}
                            onKeyDown={(e) => {
                              if(e.key === 'Enter'){
                                localStorage.removeItem('paginaAtual');
                                setTimeout(() => {
                                  if (this.props.redirectToImoveis) {
                                    // window.open('/imoveis?' + this.state.search, '_parent');
                                    localStorage.setItem('filter-search', this.state.search)

                                    localStorage.removeItem('paginaAtual')
                                    const urlSection = this.props.urlSection ? this.props.urlSection : ''
                                    this.props.onUpdate(urlSection)
                                  } else {
                                    window.open("/seu-site-fs?" + this.state.search, '_parent')
                                  }
                                }, 300);
                              }
                            }}
                          />
                          <button
                              type="button"
                              color="light" className="waves-effect waves-light search-btn"
                              ref={this.buttonRef}
                              onClick={(e) => {
                                localStorage.removeItem('paginaAtual')
                                setTimeout(() => {
                                  if (this.props.redirectToImoveis) {
                                    // window.open("/imoveis?" + this.state.search, '_parent')
                                    localStorage.setItem('filter-search', this.state.search)

                                    localStorage.removeItem('paginaAtual')
                                    const urlSection = this.props.urlSection ? this.props.urlSection : ''
                                    this.props.onUpdate(urlSection)
                                  } else {
                                    window.open("/seu-site-fs?" + this.state.search, '_parent')
                                  }
                                }, 300);
                              }}
                              data-toggle="modal"
                          >
                              <i className="ri-search-line"></i>
                          </button>
                        </Col>
                      </div>
                      <div id="moreFilters" className="moreFilters" onClick={() => {
                    if(this.state.moreFilters){
                      document.getElementById('accordion').style.display= 'none'
                      this.setState({moreFilters : false})
                      this.setState({moreFiltersIcon : 'ri-add-line'})
                    }else{
                      document.getElementById('accordion').style.display= 'block'
                      this.setState({moreFilters : true})
                      this.setState({moreFiltersIcon : 'ri-subtract-line'})
                    }
                      }}><p>Outros filtros</p><i className={this.state.moreFiltersIcon}></i></div>
                      <div className="accordion ecommerce" id="accordion">
                  <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                          <button
                          className={ this.state.isCategoryOpen0 ? 'accordion-button' : 'accordion-button collapsed'}
                          onClick={() => this.setState({isCategoryOpen0 : !this.state.isCategoryOpen0})}
                          data-bs-toggle="collapse"
                          >
                          <i className="ri-home-2-fill font-size-16 align-middle me-2"></i>{" "}
                          Categoria
                          </button>
                      </h2>
                      <Collapse
                          isOpen={this.state.isCategoryOpen0}
                          className="accordion-collapse"
                      >
                          <FiltersSection1 applyCount={this.props.count}/>
                      </Collapse>
                      </div>
                      <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                          <button
                          className={ this.state.isCategoryOpen1 ? 'accordion-button' : 'accordion-button collapsed'}
                          onClick={() => this.setState({isCategoryOpen1 : !this.state.isCategoryOpen1})}
                          data-bs-toggle="collapse"
                          >
                          <i className="ri-map-pin-2-fill font-size-16 align-middle me-2"></i>{" "}
                          Localização
                          </button>
                      </h2>
                      <Collapse
                          isOpen={this.state.isCategoryOpen1}
                          className="accordion-collapse"
                      >
                          <FiltersSection2 applyCount={this.props.count}/>
                      </Collapse>
                      </div>
    
                      <div className="accordion-item">
                      <h2 className="accordion-header" id="headingtwo">
                          <button
                          className={ this.state.isCategoryOpen2 ? 'accordion-button' : 'accordion-button collapsed'}
                          onClick={() => this.setState({isCategoryOpen2 : !this.state.isCategoryOpen2})}
                          data-bs-toggle="collapse"
                          >
                          <i className="ri-money-dollar-circle-fill font-size-16 align-middle me-2"></i>{" "}
                          Preço
                          </button>
                      </h2>
                      <Collapse
                          isOpen={this.state.isCategoryOpen2}
                          className="accordion-collapse"
                      >
                          <FiltersSection3 imoveis={this.props.imoveis} applyCount={this.props.count}/>
                      </Collapse>
                      </div>
    
                      <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                          <button
                          className={ this.state.isCategoryOpen3 ? 'accordion-button' : 'accordion-button collapsed'}
                          onClick={() => this.setState({isCategoryOpen3 : !this.state.isCategoryOpen3})}
                          data-bs-toggle="collapse"
                          >
                          <i className="ri-suitcase-fill font-size-16 align-middle me-2"></i>{" "}
                          Negociação
                          </button>
                      </h2>
                      <Collapse
                          isOpen={this.state.isCategoryOpen3}
                          className="accordion-collapse"
                      >
                          <FiltersSection7 applyCount={this.props.count}/>
                      </Collapse>
                      </div>
    
                      <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                          <button
                          className={ this.state.isCategoryOpen4 ? 'accordion-button' : 'accordion-button collapsed'}
                          onClick={() => this.setState({isCategoryOpen4 : !this.state.isCategoryOpen4})}
                          data-bs-toggle="collapse"
                          >
                          <i className="ri-ruler-2-fill font-size-16 align-middle me-2"></i>{" "}
                          Área total
                          </button>
                      </h2>
    
                      <Collapse
                          isOpen={this.state.isCategoryOpen4}
                          className="accordion-collapse"
                      >
                          <FiltersSection4 imoveis={this.props.imoveis} applyCount={this.props.count}/>
                      </Collapse>
                      </div>
    
                      <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                          <button
                          className={ this.state.isCategoryOpen5 ? 'accordion-button' : 'accordion-button collapsed'}
                          onClick={() => this.setState({isCategoryOpen5 : !this.state.isCategoryOpen5})}
                          data-bs-toggle="collapse"
                          >
                          <i className="ri-hotel-bed-fill font-size-16 align-middle me-2"></i>{" "}
                          Quartos
                          </button>
                      </h2>
    
                      <Collapse
                          isOpen={this.state.isCategoryOpen5}
                          className="accordion-collapse"
                      >
                          <FiltersSection5 applyCount={this.props.count}/>
                      </Collapse>
                      </div>
    
                      <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                          <button
                          className={ this.state.isCategoryOpen6 ? 'accordion-button' : 'accordion-button collapsed'}
                          onClick={() => this.setState({isCategoryOpen6 : !this.state.isCategoryOpen6})}
                          data-bs-toggle="collapse"
                          >
                          <i className="ri-car-fill font-size-16 align-middle me-2"></i>{" "}
                          Garagens
                          </button>
                      </h2>
    
                      <Collapse
                          isOpen={this.state.isCategoryOpen6}
                          className="accordion-collapse"
                      >
                                    
                        <FiltersSection6 applyCount={this.props.count}/>
                      </Collapse>
                      </div>
                      <Row style={{justifyContent:'space-between'}}>
                        <Col className="col-auto" style={{marginTop:'10px'}}>
                            <Button type="button" color="light" onClick={() => {
                                localStorage.removeItem('paginaAtual')
                                this.props.clearFilter()
                                setTimeout(() => {
                                    if (this.props.redirectToImoveis) {
                                      const urlSection = this.props.urlSection ? this.props.urlSection : ''
                                      this.props.onUpdate(urlSection)
                                    } else {
                                      window.open("/seu-site-fs", '_parent')
                                    }
                                }, 300);
                            }}>Remover filtros</Button>
                        </Col>
                        <Col className="col-auto" style={{marginTop:'10px'}}>
                            <Button type="button" style={{color:'#fff'}} onClick={(e) => {
                                e.preventDefault();
                                localStorage.removeItem('paginaAtual')
                                const urlSection = this.props.urlSection ? this.props.urlSection : ''
                                this.props.onUpdate(urlSection)
                            }}>Aplicar</Button>
                        </Col>
                      </Row>
                      </div>
                    </CardBody>
        }

        </Card>
        </div>
      )
  }

}
export default Filters;