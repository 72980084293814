import { useState } from 'react';
import { useEffect } from 'react';
// import '../UsersAnalytics/styles.css';
import { Link } from 'react-router-dom';
// import UsersCharts from '../UsersCharts';
import Axios from 'axios';
import { Card, CardBody, Col, Row } from 'reactstrap';
import './../../../assets/scss/custom/components/_dashboard_adm.scss'
import UsersCharts from '../UsersCharts';

function UsersAnalyticsRealEstate() {

  const [userPerCityRef, setUserPerCityRef] = useState()
  const [userPerCity, setUserPerCity] = useState([])
  const [userPremiumRef, setUserPremiumRef] = useState()
  const [userPremium, setUserPremium] = useState([])
  const [usersUf, setUsersUf] = useState([])
  const [usersTotal, setUsersTotal] = useState([])

  useEffect(() => {
    Axios.get(process.env.REACT_APP_API + '/api/auth/get-users-info',
    {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
    .then(response => {
      setUsersUf(response.data.imobiliarias)
      // setImmobilePerState(response.data.imobiliarias)
    })
    Axios.get(process.env.REACT_APP_API + '/api/auth/dashboard/get-count',
    {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
    .then(response => {
      setUsersTotal(response.data)
    })
  },[])

  useEffect(() => {
    if(userPerCityRef){
      Axios.post(process.env.REACT_APP_API + '/api/auth/get-users-state', {'state': userPerCityRef},
      {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {
          setUserPerCity(response.data.imobiliarias)
        })
    }
  }, [userPerCityRef])

  useEffect(() => {
    if(userPremiumRef){
      Axios.post(process.env.REACT_APP_API + '/api/auth/get-users-city', {'city': userPremiumRef},
      {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {
          setUserPremium(response.data.imobiliarias)
        })
    }
  }, [userPremiumRef])

  return (
    <>
      <Col xs={12}>
        <Card>
          <CardBody>
            <div className="corretorTitle">
              <h3 style={{fontSize:'20px'}}>Números de imobiliárias</h3>
            </div>
            <div className="userType corretor UsersAnalytics">
              <Row>
                <Col md={4}>
                  <div className="title">
                    <h4>Quantidade por status:</h4>
                  </div>
                  <div className="userNumbers">
                    <div className="pending card">Pendentes<span>{usersTotal?.imobiliarias_pendentes}</span></div>
                    <div className="reproved card">Reprovados<span>{usersTotal?.imobiliarias_inativas}</span></div>
                    <div className="premium card">Premium<span>{usersTotal?.imobiliarias_premium}</span></div>
                    <div className="total card">Total ativos<span>{usersTotal?.imobiliarias_total}</span></div>
                  </div>
                </Col>
                <Col md={8}>
                  <Row>
                    <Col className="title"><h4>Ranking por captações ativas:</h4></Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="ranking-container">
                          <div className="item title">
                            <div className="position">Posição:</div>
                            <div className="cover">Usuário:</div>
                            <div className="info"></div>
                            <div className="value"></div>
                            <div className="immobile">Imóveis:</div>
                          </div>
                        {usersTotal?.ranking_imobiliarias?.map((corretor, index) => (
                          <div className="item">
                            <div className="position">{index + 1}º</div>
                            <div className="cover">
                              <div className="image" style={{backgroundImage:'url(' + corretor.photo + ')'}}></div>
                            </div>
                            <div className="info">
                              <div className="name"><h3>{corretor.real_estate}</h3></div>
                              <div className="city"><small>{corretor.city}</small></div>
                            </div>
                            <div className="immobile">{corretor.count} imóveis</div>
                          </div>
                        ))}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col className="title"><h4>Quantidade por data de cadastro:</h4></Col>
              </Row>
              <Row>
                <Col>
                  <UsersCharts items={usersTotal.imobiliarias_mes} />
                </Col>
              </Row>
              <Row>
                <Col className="title"><h4>Quantidade por localidade:</h4></Col>
              </Row>
              <Row>
                <Col>
                  <div className="header">
                    <div className="col">Estado</div>
                    <div className="col">Nº de Imobiliárias</div>
                  </div>
                  <div className="table">
                    {usersUf?.sort(function(a, b){return b?.count - a?.count}).map((state, index) => (
                      <div key={index} className="body" onClick={() => setUserPerCityRef(state?.name)}>
                        <div className="col">{state?.name}</div>
                        <div className="col">{state?.count}</div>
                      </div>
                    ))}
                  </div>
                </Col>
                <Col>
                  <div className="header">
                    <div className="col">Cidade</div>
                    <div className="col">Nº de Imobiliárias</div>
                  </div>
                  <div className="table">
                    {userPerCity[0]?.sort(function(a, b){return b?.count - a?.count}).map((city, index) => (
                      <div className="body" onClick={() => setUserPremiumRef(city?.name)}>
                        <div className="col">{city?.name}</div>
                        <div className="col">{city?.count}</div>
                      </div>
                    ))}
                  </div>
                </Col>
                <Col>
                  <div className="header">
                    <div className="col">Imobiliária</div>
                    <div className="col">Premium ({userPremium?.filter(user => user.premium === 1).length})</div>
                  </div>
                  <div className="table">
                    {userPremium?.sort(function(a, b){return b?.premium - a?.premium}).map((user, index) => (
                      <Link key={index} to={'../editar-usuarios/editar-infos/'  + user.id} onClick={() => {
                        localStorage.setItem('user_type', 'C')
                      }}  className="body" target="_blank">
                        <div className="col">{user?.name}</div>
                        <div className="col">{user?.premium === 1 ? <span className="premiumTrue">Premium</span> : user?.premium === 2 ? <span className="premiumStart">Start</span> : <span className="premiumFalse">Não</span>}</div>
                      </Link>
                    ))}
                  </div>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </Col>
    </>

    );
  }
  
export default UsersAnalyticsRealEstate;  