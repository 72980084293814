import React, { Component } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";

import 'swiper/css';
import "swiper/css/navigation";

import { Navigation } from "swiper"

class ImovelCardCarousel extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={0}
                    speed={500}
                    effect='fade'
                    navigation={true}
                    modules={[Navigation]}
                    className="SwiperImovelCard"
                >
                    {this.props?.photos?.slice(0,3).map((photos, index) => (
                        <SwiperSlide><img src={photos?.medium_image}></img></SwiperSlide>
                    ))}
                    <SwiperSlide>
                        <div className="more">
                            <i className="ri-camera-fill"></i><p>Ver mais fotos</p>
                            {/* <div className="bg" style={{backgroundImage:'url(' + this.props?.photos[3]?.medium_image + ')'}}></div> */}
                        </div>

                        {this.props?.photos?.slice(3,4).map((photos, index) => (
                            <SwiperSlide style={{position: 'absolute', top: 0, filter: "blur(2px)"}}><img src={photos?.medium_image}></img></SwiperSlide>
                        ))}
                    </SwiperSlide>
                </Swiper>
            </React.Fragment>
        );
    }
}

export default ImovelCardCarousel;   