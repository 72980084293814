import {useEffect, useState} from 'react';
import { Col, Container, Input, Label, Row } from 'reactstrap';

// Form Mask
// import InputMask from "react-input-mask";
// import MaterialInput from "@material-ui/core/Input";

// import './styles.css';

function FiltersSection3({imoveis, applyCount}) {

  const[selectValueMinPrice, setSelectValueMinPrice] = useState();
  const[selectValueMaxPrice, setSelectValueMaxPrice] = useState();

  const [allImoveis, setAllImoveis] = useState([])
  
  const [count, setCount] = useState(0)

  const [valueMax, setValueMax] = useState('');
  const [valueMin, setValueMin] = useState('');

  const handleChangeMax = (event) => {
    const { value } = event.target;

    const valueOnlyDigits = value.replace(/[^\d]/g, '');

    const integerPart = valueOnlyDigits.slice(0, -2);
    const decimalPart = valueOnlyDigits.slice(-2);

    let formattedValue = '';
    for (let i = 0; i < integerPart.length; i++) {
      formattedValue += integerPart[i];
      if ((integerPart.length - i - 1) % 3 === 0 && i !== integerPart.length - 1) {
        formattedValue += '.';
      }
    }
    formattedValue = `R$ ${formattedValue},${decimalPart}`;

    setValueMax(formattedValue);
    localStorage.setItem('max_price_range_filter', value.replace(',', '').replace(/\./g, '').replace('R$ ', ''))
  };

  const handleChangeMin = (event) => {
    const { value } = event.target;

    const valueOnlyDigits = value.replace(/[^\d]/g, '');

    const integerPart = valueOnlyDigits.slice(0, -2);
    const decimalPart = valueOnlyDigits.slice(-2);

    let formattedValue = '';
    for (let i = 0; i < integerPart.length; i++) {
      formattedValue += integerPart[i];
      if ((integerPart.length - i - 1) % 3 === 0 && i !== integerPart.length - 1) {
        formattedValue += '.';
      }
    }
    formattedValue = `R$ ${formattedValue},${decimalPart}`;

    setValueMin(formattedValue);
    localStorage.setItem('min_price_range_filter', value.replace(',', '').replace(/\./g, '').replace('R$ ', ''))
  };

  useEffect(() => {
    setValueMax('')
    setValueMin('')
  }, [applyCount])

    useEffect(() => {
     if(imoveis?.length > 0){
          setValueMin('R$ ' + ((imoveis?.sort(function(a, b){return a?.sale_value - b?.sale_value})
         .slice(0,1))[0]?.sale_value/100)
         .toFixed(2).replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,"))
        
         setValueMax('R$ ' + ((imoveis.sort(function(a, b)
         {return a?.sale_value - b?.sale_value})
         .slice((imoveis.sort(function(a, b){return a?.sale_value - b?.sale_value})?.length - 1),
         imoveis.sort(function(a, b){return a?.sale_value - b?.sale_value})?.length))[0].sale_value/100)
         .toFixed(2).replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,"))

       setCount(count => count + 1)
     }
    },[imoveis])

  return (
    <Container className="sectionFilter3">
      <Row>
        <Col xs={12} className="colInputPrice" style={{marginTop:'10px'}}>
          <Label className="form-label">de</Label>
          <input
            type="text"
            value={valueMin}
            inputMode="numeric"
            onChange={handleChangeMin}
            placeholder="R$ 0,00"
          />
        </Col>
        <Col xs={12} className="colInputPrice" style={{marginTop:'10px'}}>
          <Label className="form-label">até</Label>
          <input
            type="text"
            value={valueMax}
            inputMode="numeric"
            onChange={handleChangeMax}
            placeholder="R$ 0,00"
          />
        </Col>
      </Row>
    </Container>
  );
}

export default FiltersSection3;