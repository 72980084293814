import React, { Component } from "react";

import { connect } from "react-redux";
import {
    Row,
    Col,
    Form,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    Input,
    Button,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    Card,
    CardBody,
    CardTitle,
    CardText,
} from "reactstrap";

import { Link } from "react-router-dom";

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

//Import i18n
import { withNamespaces } from "react-i18next";

//Import Megamenu
import MegaMenu from "./MegaMenu";

// Redux Store
import { toggleRightSidebar } from "../../store/actions";
import Axios from 'axios';

//Import logo Images
import logosmdark from "../../assets/images/logo-sm-dark.png";
import logodark from "../../assets/images/logo-dark.png";
import logosmlight from "../../assets/images/logo-sm-light.png";
import logolight from "../../assets/images/logo-light.png";

//Import Social Profile Images
import github from "../../assets/images/brands/github.png";
import bitbucket from "../../assets/images/brands/bitbucket.png";
import dribbble from "../../assets/images/brands/dribbble.png";
import dropbox from "../../assets/images/brands/dropbox.png";
import mail_chimp from "../../assets/images/brands/mail_chimp.png";
import slack from "../../assets/images/brands/slack.png";
import SweetAlert from 'react-bootstrap-sweetalert';

import './../../assets/scss/custom/components/_alertData.scss'
const moment = require('moment');

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user:[],
            isSearch: false,
            isSocialPf: false,
            modal_center: false,
            suporteQuestao:'',
            suporteObservacao:'',
            suporteContato:'',
            update_success:false,
            update_error:false,
            limit_date:'',
        };
        this.toggleMenu = this.toggleMenu.bind(this);
        this.toggleRightbar = this.toggleRightbar.bind(this);
        this.toggleFullscreen = this.toggleFullscreen.bind(this);
        this.tog_center = this.tog_center.bind(this);
    }
    /**
     * Toggle sidebar
     */
    toggleMenu() {
        this.props.toggleMenuCallback();
    }


    tog_center() {
        this.setState(prevState => ({
          modal_center: !prevState.modal_center
        }));
        this.removeBodyCss();
      }
      removeBodyCss() {
        document.body.classList.add("no_padding");
      }
    /**
     * Toggles the sidebar
     */
    toggleRightbar() {
        this.props.toggleRightSidebar();
    }


    toggleFullscreen() {
        if (
            !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
            !document.webkitFullscreenElement
        ) {
            // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(
                    Element.ALLOW_KEYBOARD_INPUT
                );
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }

    componentDidMount(){

            if(localStorage.getItem('access') !== 'A'){
                Axios.post(process.env.REACT_APP_API + '/api/auth/users-info', {
                    type: localStorage.getItem('access'),
                    user_id: localStorage.getItem('id')
                }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                .then(response => {
                    this.setState({user: response.data.user})
                    localStorage.setItem('user_comission', response.data.comission)
                    localStorage.setItem('user_image', response.data.user.profile_photo)
                    localStorage.setItem('your_page_id', response.data.user?.personal_page?.id)
                    localStorage.setItem('your_page_slug', response.data.user?.personal_page?.slug)
                    localStorage.setItem('status', response.data.user?.status)

                    const updatedAt = response.data.user?.updated_at;
                    if (updatedAt) {
                      const updateDate = moment(updatedAt);
                      const countdownDate = updateDate.add(4, 'days');
                      const today = moment();
                      const daysRemaining = countdownDate.diff(today, 'days');
                      this.setState({limit_date: daysRemaining})
                      localStorage.setItem('limit_date', daysRemaining)
                    } else {
                        this.setState({limit_date: ''})
                    }
                })
              }
               if(localStorage.getItem('access') === 'A'){
                 Axios.post(process.env.REACT_APP_API + '/api/auth/get-user/' + localStorage.getItem('id'), {
                    type:localStorage.getItem('access')
                 }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                 .then(response => {
                     this.setState({user: response.data})
                 })
               }

    }

    render() {
        return (
            <React.Fragment>
                <header id="page-topbar">
                    {localStorage.getItem('status') === '3' &&
                        <div className="alert-data">
                            <p>Valide seu cadastro para liberar as funcionalidades do painel de controle Fast Sale, <b>restam {this.state.limit_date} dias.</b></p><a  onClick={() => {
                        window.open('./editar-perfil', '_parent')
                    }}>Validar Agora</a>
                        </div>
                    }
                    <div className="navbar-header">
                        <div className="d-flex">

                            <div className="navbar-brand-box">
                                <Link to="#" className="logo logo-dark">
                                    <span className="logo-sm">
                                        <img src={logosmdark} alt="" height="22" />
                                    </span>
                                    <span className="logo-lg">
                                        <img src={logodark} alt="" height="20" />
                                    </span>
                                </Link>

                                <Link to="#" className="logo logo-light">
                                    <span className="logo-sm">
                                        <img src={logosmlight} alt="" height="22" />
                                    </span>
                                    <span className="logo-lg">
                                        <img src={logolight} alt="" height="20" />
                                    </span>
                                </Link>
                            </div>

                            {window.screen.width < 768 &&
                                <Button size="sm" color="none" type="button" onClick={this.toggleMenu} className="px-3 font-size-24 header-item waves-effect" id="vertical-menu-btn">
                                    <i className="ri-menu-2-line align-middle"></i>
                                </Button>
                            }

                            {/* <Form className="app-search d-none d-lg-block">
                                <div className="position-relative">
                                    <Input type="text" className="form-control" placeholder={this.props.t('Search')} />
                                    <span className="ri-search-line"></span>
                                </div>
                            </Form>

                            {/* <MegaMenu /> */}
                        </div>

                        <div className="d-flex">

                            {/* <div className="dropdown d-inline-block d-lg-none ms-2">
                                <button type="button" onClick={() => { this.setState({ isSearch: !this.state.isSearch }); }} className="btn header-item noti-icon waves-effect" id="page-header-search-dropdown">
                                    <i className="ri-search-line"></i>
                                </button>
                                <div className={this.state.isSearch === true ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show" : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"}
                                    aria-labelledby="page-header-search-dropdown">

                                    <Form className="p-3">
                                        <FormGroup className="m-0">
                                            <InputGroup>
                                                <Input type="text" className="form-control" placeholder={this.props.t('Search')} />
                                                <InputGroupAddon addonType="append">
                                                    <Button color="primary" type="submit"><i className="ri-search-line"></i></Button>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </FormGroup>
                                    </Form>
                                </div>
                            </div> */}

                            {/* <LanguageDropdown /> */}

                            {/* <Dropdown isOpen={this.state.isSocialPf} toggle={() => this.setState({ isSocialPf: !this.state.isSocialPf })} className="d-none d-lg-inline-block ms-1">
                                <DropdownToggle tag="button" className="btn header-item noti-icon waves-effect">
                                    <i className="ri-apps-2-line"></i>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end">
                                    <div className="px-lg-2">
                                        <Row className="g-0">
                                            <Col>
                                                <Link className="dropdown-icon-item" to="#">
                                                    <img src={github} alt="Github" />
                                                    <span>{this.props.t('GitHub')}</span>
                                                </Link>
                                            </Col>
                                            <Col>
                                                <Link className="dropdown-icon-item" to="#">
                                                    <img src={bitbucket} alt="bitbucket" />
                                                    <span>{this.props.t('Bitbucket')}</span>
                                                </Link>
                                            </Col>
                                            <Col>
                                                <Link className="dropdown-icon-item" to="#">
                                                    <img src={dribbble} alt="dribbble" />
                                                    <span>{this.props.t('Dribbble')}</span>
                                                </Link>
                                            </Col>
                                        </Row>

                                        <Row className="g-0">
                                            <Col>
                                                <Link className="dropdown-icon-item" to="#">
                                                    <img src={dropbox} alt="dropbox" />
                                                    <span>{this.props.t('Dropbox')}</span>
                                                </Link>
                                            </Col>
                                            <Col>
                                                <Link className="dropdown-icon-item" to="#">
                                                    <img src={mail_chimp} alt="mail_chimp" />
                                                    <span>{this.props.t('Mail Chimp')}</span>
                                                </Link>
                                            </Col>
                                            <Col>
                                                <Link className="dropdown-icon-item" to="#">
                                                    <img src={slack} alt="slack" />
                                                    <span>{this.props.t('Slack')}</span>
                                                </Link>
                                            </Col>
                                        </Row>
                                    </div>
                                </DropdownMenu>
                            </Dropdown> */}

                            <div className="dropdown d-lg-inline-block ms-1">
                                <Button color="none" type="button" className="header-item noti-icon waves-effect" >
                                    <a href="./../../../home" target="_parent">Home</a>
                                </Button>
                            </div>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <Button onClick={() => this.setState({modal_center: true})}>Ajuda</Button>
                            </div>

                            {/* <NotificationDropdown /> */}

                            <ProfileMenu user={this.state.user}/>

                            {/* <div className="dropdown d-inline-block">
                                <Button color="none" onClick={this.toggleRightbar} type="button" className="header-item noti-icon right-bar-toggle waves-effect">
                                    <i className="ri-settings-2-line"></i>
                                </Button>
                            </div> */}
                        </div>
                    </div>
                </header>
                <Modal
                        isOpen={this.state.modal_center}
                        toggle={this.tog_center}
                        centered={true}
                    >
                        <ModalHeader toggle={() => this.setState({ modal_center: false })}>
                        Criar chamado
                        </ModalHeader>
                        <ModalBody>
                            <Form id="suporte">    
                                <Row>
                                    <Col xs={12}>
                                        <Label>Onde está encontrando dificuldades?</Label>
                                        <select name="cf_encontrando_dificuldades" className="form-control" value={this.state.suporteQuestao} onChange={(e) => {
                                            this.setState({suporteQuestao: e.target.value})
                                        }}>
                                            <option value={null}>Escolha uma opção</option>
                                            <option value="Cadastrar um imóvel">Cadastrar um imóvel</option>
                                            <option value="Editar dados no meu perfil">Editar dados no meu perfil</option>
                                            <option value="Criar um agendamento">Criar um agendamento</option>
                                            <option value="Download de fotos de um imóvel">Download de fotos de um imóvel</option>
                                            <option value="Compartilhar um imóvel">Compartilhar um imóvel</option>
                                            <option value="Botão 'Fale com o captador'">Botão "Fale com o captador"</option>
                                            <option value="Integrar um imóvel">Integrar um imóvel</option>
                                            <option value="Outros">Outros</option>
                                        </select>
                                    </Col>
                                    <Col xs={12} style={{marginTop:'15px'}}>
                                        <Label>Observações</Label>
                                        <Input type="textarea" name="cf_observacoes" value={this.state.suporteObservacao} onChange={(e) => {
                                            this.setState({suporteObservacao: e.target.value})
                                        }}></Input>
                                    </Col>
                                    <Col xs={12} style={{marginTop:'15px'}}>
                                        <Label>Como prefere ser contatado?</Label>
                                        <select name="cf_como_prefere_ser_contatado" className="form-control" value={this.state.suporteContato} onChange={(e) => {
                                            this.setState({suporteContato: e.target.value})
                                        }}>
                                            <option value={null}>Escolha uma opção</option>
                                            <option value="Whatsapp">WhatsApp</option>
                                            <option value="Ligação">Ligação</option>
                                            <option value="E-mail">E-mail</option>
                                        </select>
                                    </Col>
                                    <Row style={{marginLeft:'0', marginTop:'15px'}}>
                                        <Col xs={12} style={{padding:'0'}}>
                                            <Card color="dark" className="text-white-50">
                                                <CardBody style={{padding: '0.75rem 0.75rem'}}>
                                                    <CardTitle className="text-white" style={{marginBottom:'0'}}><i className="mdi mdi-alert-circle-outline me-3"></i>Atenção</CardTitle>
                                                    <CardText>Usuários Premium tem prioridade no atendimento.</CardText>
                                                    <CardText>Financeiro e Jurídico: (47) 9941-1891</CardText>
                                                    <CardText>Operacional e Comercial: (47) 4108-1865</CardText>
                                                    <small className="text-muted">*Os atendimentos são realizados de segunda a sexta das 8h até 18h.</small>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Row>
                            </Form>
                        </ModalBody>
                        <ModalFooter>
                            <Row style={{justifyContent:'flex-end', alignItems:'flex-end'}}>
                                <Col className="col-auto">
                                <Button className="btn-light" color="#333" onClick={(e) => {
                                    this.setState({ modal_center: false })
                                }}>Fechar</Button>
                                </Col>
                                <Col className="col-auto">
                                {(this.state.suporteContato !== ''
                                && this.state.suporteObservacao !== ''
                                && this.state.suporteQuestao !== ''
                                ) ?
                                    <Button className="btn-success" onClick={() => {

                                        const data = new FormData()

                                        data.append('name', this.state.user.name)
                                        data.append('email', this.state.user.email)
                                        data.append('phone', this.state.user.phone)
                                        data.append('city', this.state.user.city)
                                        data.append('state',this.state.user.state )
                                        data.append('premium', this.state.user.premium === 1 ? 'Sim' : 'Não')
                                        data.append('cf_tipo_usuario', localStorage.getItem('access') === 'C' ? 'Corretor' : localStorage.getItem('access') === 'P' ? 'Proprietário' : 'Imobiliária')
                                        data.append('cf_como_prefere_ser_contatado', this.state.suporteContato)
                                        data.append('cf_observacoes', this.state.suporteObservacao)
                                        data.append('cf_encontrando_dificuldades', this.state.suporteQuestao)

                                        Axios.post(process.env.REACT_APP_API + '/api/auth/novo-chamado', data,
                                        {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                                        .then(response => {
                                            this.setState({update_success: true})
                                            setTimeout(() => {
                                                this.setState({ modal_center: false })
                                            }, 3500);
                                        })
                                        .catch(response => {
                                            this.setState({update_error: true})
                                        })

                                    }}>
                                        Enviar chamado
                                    </Button>
                                    :
                                    <Button disabled>Enviar chamado</Button>
                                }

                                </Col>
                            </Row>
                        </ModalFooter>
                </Modal>


                {/* Sucesso na atualização */}

                {this.state.update_success ? (
                    <SweetAlert
                    title="Chamado criado com sucesso!"
                    timeout={4000}
                    showCloseButton={false}
                    showConfirm={false}
                    success
                    onConfirm={() => this.setState({ update_success: false })}
                    ></SweetAlert>
                    ) : null}

                {/* Falha na atualização */}

                {this.state.update_error ? (
                    <SweetAlert
                    title="O chamado não pode ser criado, verique as informações e tente novamente."
                    timeout={4000}
                    showCloseButton={false}
                    showConfirm={false}
                    error
                    onConfirm={() => this.setState({ update_error: false })}
                    ></SweetAlert>
                    ) : null}
                    

            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType };
};

export default connect(mapStatetoProps, { toggleRightSidebar })(withNamespaces()(Header));
