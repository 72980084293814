import Axios from 'axios';
import React, { Component } from 'react';
import { Card, CardBody, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, CardImg, Row, CardTitle, CardText, Button, Badge } from "reactstrap";

//Simple bar
import SimpleBar from "simplebar-react";
import './../../assets/scss/custom/components/_horizontalCard.scss'
import EnterpriseHorizontalCard from './EnterpriseHorizontalCard';

class EnterpriseLastView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            imoveis:[],
        }
    }

    componentDidMount(){
            Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/enterprises-last-five', {
                type_action: 'view',
                construction_id: localStorage.getItem('id')
            },
            {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(response => {
                this.setState({imoveis: response.data})
            })
    }

    render() {
        return (
            <React.Fragment>
                <Col xs={12}>
                    <Card>
                        <CardBody>
                            <h4 className="card-title mb-4">Últimos empreendimentos visualizados pelos corretores</h4>
                            <span style={{
                                width:'100%',
                                height:'1px',
                                backgroundColor:"#888",
                                margin:'10px 0 20px',
                                display:'block'
                            }}></span>
                            <SimpleBar style={{ maxHeight: "350px", minHeight: "350px", overflow:'hidden auto'}}>
                                {this.state.imoveis?.length > 0 ? (this.state.imoveis?.map((imovel, index) => (
                                    <EnterpriseHorizontalCard key={index} imovel={imovel}/>
                                ))):(
                                    <p>Você ainda não tem empreendimentos cadastrados.</p>
                                )}
                            </SimpleBar>
                        </CardBody>
                    </Card>
                </Col>
            </React.Fragment>
        );
    }
}

export default EnterpriseLastView;